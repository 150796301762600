import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import { RadioGroup } from 'components';

const QuestionGender: React.FC = () => (
  <Field
    name="gender"
    label={<FormattedMessage id="q.gender.label" />}
    component={RadioGroup}
    options={[
      {
        label: <FormattedMessage id="q.gender.answer.female" />,
        value: 'female',
      },
      {
        label: <FormattedMessage id="q.gender.answer.male" />,
        value: 'male',
      },
      {
        label: <FormattedMessage id="q.gender.answer.other" />,
        value: 'other',
      },
      {
        label: <FormattedMessage id="q.gender.answer.opt_out" />,
        value: 'opt_out',
      },
    ]}
  />
);

export default QuestionGender;
