import { merge } from 'lodash';
import { getProgramMetricConfig } from '@perts/config';
import { SurveyBlock, SurveyBlockPage, SurveyPageControls } from 'types';
import { QuestionLikertScale } from 'components';
import whenLearningCondition from './whenLearningCondition';

const blockFromMetricsConfig = (
  surveyLabel: string,
  metricLabel: string,
  metricName: string,
  pageLabel: string,
  props: SurveyPageControls = {},
) => {
  const config = {
    label: metricLabel,
    name: metricName,
  };

  const metricConfig = getProgramMetricConfig(surveyLabel);
  const metricConfigItems = metricConfig.metricIndex[config.label].items;

  const block: SurveyBlock = {
    component: 'Block',
    label: pageLabel,
    props: merge(
      {},
      {
        showWhen: whenLearningCondition(config.label),
        waitBeforeNext: true,
      },
      props,
    ),
    pages: metricConfigItems.map(
      (metric): SurveyBlockPage => ({
        component: 'Page',
        questions: [
          {
            component: QuestionLikertScale,
            label: metric.label,
            props: {
              likertN: metric.likertN,
              likertReverse: metric.likertReverse,
              messages: metric.prompts,
              options: metric.options,
            },
          },
        ],
      }),
    ),
  };

  return { config, block };
};

export default blockFromMetricsConfig;
