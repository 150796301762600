import styled from 'styled-components';
import theme from 'components/theme';

export const Warning = styled.div`
  padding: ${theme.units.fieldPadding};
  margin-bottom: ${theme.units.fieldPadding};

  border-radius: ${theme.units.borderRadius};

  color: #ffffff;
  background: ${theme.colors.red};

  text-align: center;
  font-weight: bold;
`;
