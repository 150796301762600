// Helper component that wraps up the boilerplate of providing the locale
// messages and locale options to a RadioGroup Question and selecting which
// message and options will be used based on current locale.
//
// Example usage:
//
// import locales from 'i18n/locales';
//
// const messages = {
//   [locales.ENGLISH]: 'Question text in English.',
//   [locales.SPANISH]: 'Question text in Spanish.',
// };
//
// const options = {
//   [locales.ENGLISH]: [
//     {
//       label: 'Yes',
//       value: 2,
//     },
//     {
//       label: 'No',
//       value: 1,
//     },
//   ],
//   [locales.SPANISH]: [
//     {
//       label: 'Sí',
//       value: 2,
//     },
//     {
//       label: 'No',
//       value: 1,
//     },
//   ],
// };
//
// <QuestionLikertScale
//   label="question_label"
//   messages={messages}
//   options={options}
// />

import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LocaleContext } from 'i18n/locales';
import { Field, Question, RadioGroup } from 'components';

type Message = {
  label: string;
  value: number;
};

type Props = {
  label: string;
  messages: {
    [locale: string]: Message;
  };
  options: { [locale: string]: { label: any; value: any }[] };
};

const QuestionRadioGroup = ({ label, messages, options, ...rest }: Props) => {
  const { surveyLabel } = useParams<{ surveyLabel: string }>();
  const { locale } = useContext<{ locale: string }>(LocaleContext);

  // When no message has been defined for the requested locale, fallback to
  // displaying the question label for development purposes.
  const questionLabel: Message | Props['label'] =
    (messages && messages[locale]) || label;

  if (typeof questionLabel === 'string' && questionLabel === label) {
    // If we haven't defined a message for the requested locale, it's probably a
    // mistake. Notify so we can get it added.
    console.error(
      `Question ${label} (${surveyLabel}) has no "${locale}" i18n message assigned.`,
    );
  }

  // Determine which options to provide based on locale.
  const radioOptions = options[locale];

  return (
    <Question>
      <Field
        name={label}
        label={questionLabel}
        component={RadioGroup}
        options={radioOptions}
        {...rest}
      />
    </Question>
  );
};

export default QuestionRadioGroup;
