import { SurveyConfig } from 'types';

import blockBelongingCertainty from 'surveys/common/block-belonging-certainty';
import blockIdentitySafety from 'surveys/common/block-identity-safety';
import blockInstitutionalGrowthMindset from 'surveys/common/block-institutional-gms';
import blockSelfEfficacy from 'surveys/common/block-self-efficacy';
import blockSocialBelonging from 'surveys/common/block-social-belonging';
import blockSocialConnectedness from 'surveys/common/block-social-connectedness';
import blockTrustAndFairness from 'surveys/common/block-trust-fairness';

import blockFidelity from 'surveys/common/block-fidelity';
import blockDemographicsRaceGender from 'surveys/common/block-race-gender22';
import blockDemographicsOther22 from 'surveys/common/block-demographics-other22';

import SurveyIntroduction from './SurveyIntroduction';
import QuestionCompleteCode from 'surveys/common/QuestionCompletionCode';

export const ascend23: SurveyConfig = {
  name: 'Ascend',
  label: 'ascend23',
  survey: [
    {
      component: 'Page',
      label: 'introduction',
      progress: 1,
      props: {
        waitBeforeNext: true,
      },
      questions: [
        {
          label: 'intro_your_opinion',
          component: SurveyIntroduction,
          props: {},
        },
      ],
    },

    blockBelongingCertainty,
    blockIdentitySafety,
    blockInstitutionalGrowthMindset,
    blockSelfEfficacy,
    blockSocialBelonging,
    blockSocialConnectedness,
    blockTrustAndFairness,

    blockFidelity,
    blockDemographicsRaceGender,
    blockDemographicsOther22,
    {
      component: 'Page',
      label: 'thankyou',
      progress: 100,
      updateParticipantData: {
        pdKey: 'saw_demographics',
        pdValue: 'true',
      },
      questions: [
        {
          label: 'surveycomplete',
          component: QuestionCompleteCode,
          props: {
            codeSuffix: '6158',
          },
        },
      ],
    },
  ],
};
