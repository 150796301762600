import { useParams } from 'react-router-dom';
import { Warning } from 'components';
import { PathParams } from 'index.d';
import { useResponseContext } from 'services/responseContext';

export const DisplayPreviewWarning = () => {
  const response = useResponseContext();
  const { pageLabel } = useParams<PathParams>();
  const shouldDisplay =
    response?.meta?.testing === 'true' && pageLabel === 'introduction';

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Warning role="status">
      <span>
        Do not share this preview, survey responses will not be recorded.
      </span>
    </Warning>
  );
};
