// Wrapper for formik's Field component. This component renders the formik Field
// component and additionally renders the matching open response question.

import React from 'react';
import { Field as FormikField } from 'formik';
import { FormattedMessage } from 'react-intl';
import { FlexItem, FlexRow } from 'perts-ui';
import { Question, Show, TextField, TextToSpeechButton } from 'components';
import openResponseFieldName from '../../utils/openResponseFieldName';
import whenOpenResponse from '../../utils/whenOpenResponse';
import { useResponseContext } from 'services/responseContext';

type Props = {
  [propName: string]: any;
};

const Field: React.FC<Props> = (props) => {
  const response = useResponseContext();

  // The field labels for these items should follow the convention of placing
  // the prefix `or_` before the label of the corresponding scale item. E.g.,
  // the open-ended text field for the item `mw1_2` should be `or_mw1_2`.
  // (This follows the same convention we had with Qualtrics)
  const orFieldName = openResponseFieldName(props.name);

  const fieldLabel = <FormattedMessage id="ui.openResponse.label" />;

  return (
    <>
      <FormikField {...props} />

      <Show when={whenOpenResponse(orFieldName)(response)}>
        <Question.Content>
          <FlexRow>
            <FlexItem>
              <FormikField
                id={orFieldName}
                name={orFieldName}
                component={TextField}
                label={fieldLabel}
                multiline
                rows="4"
                data-test="open-response-field"
              />
            </FlexItem>
            <FlexItem>
              <TextToSpeechButton text={fieldLabel} />
            </FlexItem>
          </FlexRow>
        </Question.Content>
      </Show>
    </>
  );
};

export default Field;
