const colorsBrand = {
  primary: '#1C9DE3',
  primaryDark: '#061D2A',
  secondary: '#061D2A', // alias for primaryDark
  primaryLight: '#ACDBF4',
  grayDark: '#202020',
  grayMedium: '#464646',
  grayLight: '#DDDDDD',
  green: '#44E790',
  red: '#E74444',
  yellow: '#E7DA44',
  offWhite: '#F7F8FA',
  white: '#FFFFFF',
};

const colors = {
  ...colorsBrand,
  background: colorsBrand.offWhite,
  disabled: colorsBrand.secondary,
  focus: colorsBrand.primaryLight,
};

const units = {
  appWidth: '820px',
  borderRadius: '3px',
  checkboxSize: '20px',
  fieldPadding: '12px',
  fontSizeBase: '1rem',
};

export default {
  colors,
  units,
};
