import { SurveyBlock } from 'types';
import QuestionDemographicsInsecureFood from './QuestionDemographicsInsecureFood';
import QuestionDemographicsInsecureFinancial from './QuestionDemographicsInsecureFinancial';
import sawDemographics from 'utils/sawDemographics';

export const config = {
  label: 'demographics',
  name: 'Demographics',
};

const block: SurveyBlock = {
  component: 'Block',
  label: 'demographicsOther',
  props: {
    hideWhen: sawDemographics,
    waitBeforeNext: true,
  },
  pages: [
    {
      component: 'Page',
      questions: [
        {
          component: QuestionDemographicsInsecureFood,
          label: 'food_insecure',
          props: {},
        },
      ],
    },
    {
      component: 'Page',
      questions: [
        {
          component: QuestionDemographicsInsecureFinancial,
          label: 'fin_insecure',
          props: {},
        },
      ],
    },
  ],
};

export default block;
