import styled from 'styled-components/macro';
import theme from 'components/theme';

const SurveyHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: middle;

  margin-bottom: ${theme.units.fieldPadding};

  /* LocaleSelector */
  > div:last-child {
    margin-left: 12px;
  }

  /* TextToSpeechToggle */
  > div:first-child {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    label {
      margin-bottom: 0;
    }
  }
`;

export default SurveyHeader;
