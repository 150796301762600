// Note: HTML forms allow you to submit checkbox inputs like:
//
//   <input type="checkbox" name="fin_insecure" value="fin_insecure_1" />
//   <input type="checkbox" name="fin_insecure" value="fin_insecure_2" />
//   <input type="checkbox" name="fin_insecure" value="fin_insecure_3" />
//
// And upon submit, the "fin_insecure" form value would equal:
//
//   ["fin_insecure_1", "fin_insecure_2", "fin_insecure_3"]
//
// But we instead separate out each checkbox option:
//
//   <input type="checkbox" name="fin_insecure_1" value="true" />
//   <input type="checkbox" name="fin_insecure_2" value="true" />
//   <input type="checkbox" name="fin_insecure_3" value="true" />
//
// And upon submit, to provide the following:
//
//   { fin_insecure_1: true, fin_insecure_2: true, fin_insecure_3: true }
//
// This explains why the `options` prop that you'll need to provide here
// contains separate `name` props for each checkbox option instead of providing
// a separate `value` option. The `value` options will be either `true`, `false`
// or `undefined` (when nothing was ever selected).

import React from 'react';
import { FieldProps } from 'formik';

import {
  CheckboxWithLabel,
  FieldSet,
  FlexItem,
  FlexRow,
  Legend,
} from 'perts-ui';
import { Question, Show, TextToSpeechButton } from 'components';

type Option = { name: any; label: any; value: any };

type CheckboxGroupType = {
  label: any;
  fieldsets: [
    {
      legend?: any;
      options: [Option];
      otherField?: any;
    },
  ];
  // Display a "None of the above" option. Provide a string to change the text
  // value that appears for this option.
  noneOfTheAbove?: boolean | string;
  // When using `noneOfTheAbove`, you must also specify a question name. This
  // is the key that will be used to store in the response's answers.
  noneOfTheAboveName?: string;

  // Called when an individual Checkbox value is changed. This is the, optional,
  // user provided version of onChange, not the built-in Formik version.
  //   <Field onChange={(e) => null} component={CheckboxGroup} />
  onChange?: (e: { target: { name: string; value: any } }) => void;
} & FieldProps;

const CheckboxGroup: React.FC<CheckboxGroupType> = (props) => {
  const { label, fieldsets, noneOfTheAbove, noneOfTheAboveName = '' } = props;
  const { onChange, value } = props.field;
  const { isSubmitting } = props.form;

  if (noneOfTheAbove && noneOfTheAboveName === '') {
    console.error(
      'You must provide a `noneOfTheAboveName` when using `noneOfTheAbove`.',
    );
  }

  if (noneOfTheAbove && props.onChange) {
    // eslint-disable-next-line no-console
    console.warn(
      'Using a custom `onChange` function with `noneOfTheAbove` is untested.',
    );

    // https://github.com/PERTS/perts/pull/1944
    // Allowing custom onChange was added to supported the race followup
    // question. Additional work may be needed to support custom onChange
    // when also utilizing the noneOfTheAbove feature.
  }

  const noneOfTheAboveLabel =
    noneOfTheAbove && typeof noneOfTheAbove === 'string'
      ? noneOfTheAbove
      : 'None of the above.';

  const isChecked = (n: string) => n in value && value[n];

  // Clears all options found within all fieldsets. This will not clear the
  // "None of the above" option because it is not found within fieldsets.
  const clearAllOptions = () => {
    fieldsets.forEach((fieldset) => {
      fieldset.options.forEach((option) => {
        // Create an onChange event formatted as Formik expects;
        const event = {
          target: {
            name: option.name,
            value: false,
          },
        };
        onChange(event);
      });
    });
  };

  // Clears the "None of the above" option.
  const clearNoneOfTheAbove = () => {
    // Create an onChange event formatted as Formik expects;
    const event = {
      target: {
        name: noneOfTheAboveName,
        value: false,
      },
    };
    onChange(event);
  };

  // Transform name/value pairing to what Formik's onChange expects.
  const handleChange = (n: string) => {
    const event = {
      target: {
        name: n,
        value: !isChecked(n),
      },
    };
    onChange(event);
    props.onChange?.(event);

    // If "None of the above" has been selected, then clear all other options.
    if (n === noneOfTheAboveName && event.target.value) {
      clearAllOptions();
    }

    // If an option other than "None of the above" has been selected, then clear
    // the "None of the above" option.
    if (n !== noneOfTheAboveName && event.target.value) {
      clearNoneOfTheAbove();
    }
  };

  return (
    <>
      <Question.Title>{label}</Question.Title>

      <Question.Content>
        {fieldsets.map((fieldset, fieldsetIndex) => (
          <FieldSet key={fieldsetIndex}>
            {fieldset.legend && (
              <FlexRow>
                <FlexItem>
                  <Legend>{fieldset.legend}</Legend>
                </FlexItem>
                <FlexItem grow={0}>
                  <TextToSpeechButton text={fieldset.legend} />
                </FlexItem>
              </FlexRow>
            )}

            {fieldset.options.map((option) => (
              <FlexRow key={option.name}>
                <FlexItem>
                  <CheckboxWithLabel
                    value={option.value}
                    label={option.label}
                    name={option.name}
                    disabled={isSubmitting}
                    checked={isChecked(option.name)}
                    onChange={() => handleChange(option.name)}
                  />
                </FlexItem>
                <FlexItem grow={0}>
                  <TextToSpeechButton text={option.label} />
                </FlexItem>
              </FlexRow>
            ))}

            {fieldset.otherField}
          </FieldSet>
        ))}

        <Show when={noneOfTheAbove}>
          <CheckboxWithLabel
            value={true}
            label={noneOfTheAboveLabel}
            name={noneOfTheAboveName}
            disabled={isSubmitting}
            checked={isChecked(noneOfTheAboveName)}
            onChange={() => handleChange(noneOfTheAboveName)}
            data-test="none-of-the-above"
          />
        </Show>
      </Question.Content>
    </>
  );
};

export default CheckboxGroup;
