import locales from 'i18n/locales';
import { SurveyBlock } from 'types';
import { QuestionLikertScale } from 'components';
import QuestionFidelityHonest from './QuestionFidelityHonest';

export const config = {
  label: 'fidelity',
  name: 'Fidelity',
  pageLabel: 'fidelity',
};

const block: SurveyBlock = {
  component: 'Block',
  label: config.pageLabel,
  props: {
    waitBeforeNext: true,
    contextReminderProp: 'class_name',
  },
  pages: [
    {
      component: 'Page',
      questions: [
        {
          component: QuestionLikertScale,
          label: 'fidelity_school_better',
          props: {
            likertN: 5,
            messages: {
              [locales.ENGLISH]: (
                <>
                  My answers to this survey will be used to make things better
                  for me and for students.
                </>
              ),
              [locales.SPANISH]: (
                <>
                  Mis respuestas a esta encuesta se utilizarán para mejorar
                  cosas para mí y para estudiantes.
                </>
              ),
            },
          },
        },
      ],
    },
    {
      component: 'Page',
      questions: [
        {
          component: QuestionFidelityHonest,
          label: 'fidelity_honest',
          props: {},
        },
      ],
    },
  ],
};

export default block;
