// NOTE
// - learning condition separator is `-` (hyphen)
// - question label separator is `_` (underscore)

export const surveyLabel = 'sesStudent23';

export const learningConditions = [
  'growth-mindset',
  'school-belonging',
  'school-identity-safety',
  'effort',
  'school-student-voice',
  'ses-student23-math',
  'ses-student23-english',
  'ses-student23-science',
  'ses-student23-history',
];
