import firebase from 'firebase/compat/app';
// import 'firebase/analytics';
import 'firebase/compat/performance';
import 'firebase/compat/firestore';
import getFirebaseConfig from './firebaseConfig';
import isLocalhost from 'utils/isLocalhost';
import isTesting from 'utils/isTesting';
import { getAnalytics, logEvent } from 'firebase/analytics';

export const RESPONSES_COLLECTION = 'responses';
export const ANSWERS_COLLECTION = 'answers';

export { default as addResponse } from './addResponse';
export { default as getResponseAndListen } from './getResponseAndListen';
export { default as updateResponse } from './updateResponse';

// Custom env variables must be prepended by REACT_APP_
// https://create-react-app.dev/docs/adding-custom-environment-variables/
const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const firebaseConfig = getFirebaseConfig(firebaseProjectId);
const app = firebase.initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
export const fbLogEvent = (...args) => logEvent(analytics, ...args);

if (!isLocalhost() && !isTesting()) {
  // Don't record performance in local development.
  // Requires a browser environment, so also don't use while testing.

  // Initialize Performance Monitoring
  // https://firebase.google.com/docs/perf-mon/get-started-web
  firebase.performance();
}

export const firestore = firebase.firestore();

if (isLocalhost()) {
  if (!isTesting()) {
    // eslint-disable-next-line no-console
    console.debug('[Firebase] Using Firestore emulator.');
    // eslint-disable-next-line no-console
    console.debug(
      '[Firebase] Firestore emulator clears database contents when shut down.',
    );
  }

  // Configure Firebase to connect to emulators.
  // https://firebase.google.com/docs/emulator-suite/connect_and_prototype
  const firestoreSettings = {};

  firestoreSettings.host = `localhost:${process.env.REACT_APP_EMU_FIRESTORE}`;
  firestoreSettings.ssl = false;

  // NEEDED?
  // firestoreSettings.projectId = firebaseConfig.projectId;

  if (window.Cypress) {
    // Pass long polling setting to Firestore when running in Cypress.
    // Needed for Firestore support in Cypress.
    // see https://github.com/cypress-io/cypress/issues/6350
    // see https://github.com/cypress-io/cypress/issues/2374
    // see https://github.com/firebase/firebase-js-sdk/issues/1674
    firestoreSettings.experimentalForceLongPolling = true;

    // For direct to Firebase testing, this might be a useful resource:
    // https://github.com/prescottprue/cypress-firebase
  }

  firestore.settings(firestoreSettings);
}
