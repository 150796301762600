import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SurveyPage } from 'types';

export const useRouteNext = (
  page: SurveyPage,
  pageNext: string | undefined,
) => {
  const { questions } = page;
  const history = useHistory();
  const [questionRoute, setQuestionRoute] = useState(questions[0].label);

  // setNextRoute is passed as a prop to pages so they can customize what
  // page comes next. This allows arbitrary branching. See sesTeacher23 for
  // an example.
  const [next, setNextRoute] = useState(pageNext);

  useEffect(() => {
    // Reset back to first question on page change.
    setQuestionRoute(questions[0].label);
    setNextRoute(pageNext);
  }, [pageNext, questions]);

  const visibleIndex = questions.findIndex(
    (c: any) => c.label === questionRoute,
  );
  const visible = questions[visibleIndex] || questions[0];

  const visibleIsLastQuestion = visibleIndex >= questions.length - 1;
  const isInternalRouting = questions.length > 1 && !visibleIsLastQuestion;

  const routeNext = () => {
    if (!next) {
      return;
    }

    if (isInternalRouting) {
      setQuestionRoute(questions[visibleIndex + 1].label);
    } else {
      next && history.push(next);
    }
  };

  window && window.scrollTo(0, 0);

  return { routeNext, visible, setNextRoute };
};
