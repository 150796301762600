import React, { useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE, LocaleContext } from './locales';
import messages from './messages';

export const IntlLocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const IntlMessagesProvider = ({ children }) => {
  const { locale } = useContext(LocaleContext);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      textComponent={React.Fragment}
      messages={messages[locale]}
    >
      {children}
    </IntlProvider>
  );
};
