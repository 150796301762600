import { getUserLocales } from 'get-user-locale';
import getSupportedLocales from './getSupportedLocales';
import { DEFAULT_LOCALE } from './locales';

// Returns the first supported locale of the survey with `surveyLabel`, in the
// user's preferred locales array (based on `get-user-locale` package).
//
// Example:
//   supportedLocales: ['en', 'es'];
//   userPreferredLocales: ['en-US', 'en', 'es'];
//   returns 'en';

async function getUserLocaleFromSupported(surveyLabel) {
  const supportedLocalesFromConfig =
    (await getSupportedLocales(surveyLabel)) || [];

  // https://www.rfc-editor.org/rfc/bcp/bcp47.txt
  // Section 2.1.1 "At all times, language tags and their subtags, including
  // private use and extensions, are to be treated as case insensitive."
  // So, perform case insensitive comparisons.
  const supportedLocales = supportedLocalesFromConfig.map((l) =>
    l.toLowerCase(),
  );

  // Currently, we only support language and not region locale selection. So
  // drop the region portion of the locale preferences.
  const userPreferredLocales = getUserLocales().map((locale) => {
    const [language] = locale.split('-');
    return language.toLowerCase();
  });

  // eslint-disable-next-line no-console
  console.debug(
    `[locale] Survey "${surveyLabel}" supports [${supportedLocales}].`,
  );

  // eslint-disable-next-line no-console
  console.debug(
    `[locale] User preferred locales are [${userPreferredLocales}].`,
  );

  for (const locale of userPreferredLocales) {
    if (supportedLocales.includes(locale)) {
      // eslint-disable-next-line no-console
      console.debug(
        `[locale] User preferred locale "${locale}" is supported by "${surveyLabel}".`,
      );

      return locale;
    }
  }

  // eslint-disable-next-line no-console
  console.debug(`[locale] Falling back to default "${DEFAULT_LOCALE}".`);
  return DEFAULT_LOCALE;
}

export default getUserLocaleFromSupported;
