import { FaCheck } from 'react-icons/fa';
import { locales } from 'i18n';
import { UnorderedList, ListItem } from 'perts-ui';
import { defaultYourClassName } from 'surveys/catalyze21/config';
import useMeta from 'services/useMeta';

import { LocaleSwitch, Question, TextToSpeechContent } from 'components';

const messages = {
  [locales.ENGLISH]: () => (
    <>
      <Question.Title>Your opinion matters!</Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            Leaders in your organization want to know how to make it a better
            place for you to work. This 5-minute survey is a chance for you to
            provide honest, private feedback.
          </p>
          <UnorderedList>
            <ListItem icon={<FaCheck />}>
              Each question is about your experiences with{' '}
              {useMeta('class_name', defaultYourClassName)}.
            </ListItem>
            <ListItem icon={<FaCheck />}>
              To protect your privacy, we will not share your name with anyone
              in connection with your answers. The reports only display
              responses that have been grouped together with five people or
              more. However, the person who invited you to the survey will be
              able to see whether or not you completed the survey.
            </ListItem>
            <ListItem icon={<FaCheck />}>
              Please be honest. There are no right or wrong answers.
            </ListItem>
          </UnorderedList>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),
};

export default () => (
  <LocaleSwitch label="SurveyIntroduction" messages={messages} />
);
