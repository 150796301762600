import React from 'react';
import { FormattedMessage } from 'react-intl';

export type LikertScaleOptions = {
  [key: number]: {
    label: React.ReactElement;
    value: number;
  }[];
};

export const likertScaleOptions: LikertScaleOptions = {
  2: [
    {
      label: <FormattedMessage id="q.likert.answer.disagree" />,
      value: 1,
    },
    { label: <FormattedMessage id="q.likert.answer.agree" />, value: 2 },
  ],
  3: [
    {
      label: <FormattedMessage id="q.likert.answer.disagree" />,
      value: 1,
    },
    {
      label: <FormattedMessage id="q.likert.answer.neither" />,
      value: 2,
    },
    { label: <FormattedMessage id="q.likert.answer.agree" />, value: 3 },
  ],
  4: [
    {
      label: <FormattedMessage id="q.likert.answer.stronglyDisagree" />,
      value: 1,
    },
    {
      label: <FormattedMessage id="q.likert.answer.disagree" />,
      value: 2,
    },
    { label: <FormattedMessage id="q.likert.answer.agree" />, value: 3 },
    {
      label: <FormattedMessage id="q.likert.answer.stronglyAgree" />,
      value: 4,
    },
  ],
  5: [
    {
      label: <FormattedMessage id="q.likert.answer.stronglyDisagree" />,
      value: 1,
    },
    {
      label: <FormattedMessage id="q.likert.answer.disagree" />,
      value: 2,
    },
    {
      label: <FormattedMessage id="q.likert.answer.neither" />,
      value: 3,
    },
    { label: <FormattedMessage id="q.likert.answer.agree" />, value: 4 },
    {
      label: <FormattedMessage id="q.likert.answer.stronglyAgree" />,
      value: 5,
    },
  ],
  6: [
    {
      label: <FormattedMessage id="q.likert.answer.stronglyDisagree" />,
      value: 1,
    },
    {
      label: <FormattedMessage id="q.likert.answer.disagree" />,
      value: 2,
    },
    {
      label: <FormattedMessage id="q.likert.answer.slightlyDisagree" />,
      value: 3,
    },
    {
      label: <FormattedMessage id="q.likert.answer.slightlyAgree" />,
      value: 4,
    },
    { label: <FormattedMessage id="q.likert.answer.agree" />, value: 5 },
    {
      label: <FormattedMessage id="q.likert.answer.stronglyAgree" />,
      value: 6,
    },
  ],
  7: [
    {
      label: <FormattedMessage id="q.likert.answer.stronglyDisagree" />,
      value: 1,
    },
    {
      label: <FormattedMessage id="q.likert.answer.disagree" />,
      value: 2,
    },
    {
      label: <FormattedMessage id="q.likert.answer.slightlyDisagree" />,
      value: 3,
    },
    {
      label: <FormattedMessage id="q.likert.answer.neither" />,
      value: 4,
    },
    {
      label: <FormattedMessage id="q.likert.answer.slightlyAgree" />,
      value: 5,
    },
    { label: <FormattedMessage id="q.likert.answer.agree" />, value: 6 },
    {
      label: <FormattedMessage id="q.likert.answer.stronglyAgree" />,
      value: 7,
    },
  ],
};
