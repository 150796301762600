import PropTypes from 'prop-types';
import { useResponseContext } from 'services/responseContext';
import { useIntl, FormattedMessage } from 'react-intl';
import extractTextFromJsx from 'utils/extractTextFromJsx';
import useSpeech from 'utils/useSpeech';
import TextToSpeechButtonStyled from './TextToSpeechButtonStyled';

const TextToSpeechButton = ({ text, size }) => {
  const response = useResponseContext();
  const intl = useIntl();

  // When the FormattedMessage component is used to display an input field's
  // label, extractTextFromJsx is unable to access the intended text because
  // it's not in the component tree. Instead, we use intl.formatMessage to
  // feed it the message string directly.

  const textToRead =
    text.type === FormattedMessage
      ? intl.formatMessage({
          id: text.props.id,
        })
      : extractTextFromJsx(text);

  const { speak, cancel, speaking } = useSpeech(textToRead);

  if (!window.speechSynthesis) {
    return null;
  }

  if (!response || !response.textToSpeech) {
    return null;
  }

  const onClick = () => {
    if (speaking) {
      cancel();
    } else {
      speak();
    }
  };

  return (
    <TextToSpeechButtonStyled
      type="button"
      size={size}
      onClick={onClick}
      isSpeaking={speaking}
    >
      {speaking ? (
        <span>Stop reading text</span>
      ) : (
        <span>Start reading text</span>
      )}
    </TextToSpeechButtonStyled>
  );
};

TextToSpeechButton.propTypes = {
  size: PropTypes.number,
  text: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};

TextToSpeechButton.defaultProps = {
  size: 50,
};

export default TextToSpeechButton;
