import { LocaleSwitch, Question, TextToSpeechContent } from 'components';
import { locales } from 'i18n';

const messages = {
  [locales.ENGLISH]: () => (
    <>
      <Question.Title>
        For the next three questions, think about your science class.
      </Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            For the next three questions, think about your <u>science</u> class.
            Science includes subjects like Biology, Chemistry, Physics, and
            Physical Sciences.
          </p>
          <p>
            If you take more than one science class, please think about{' '}
            <u>all</u> of your science classes.
          </p>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),

  [locales.SPANISH]: () => (
    <>
      <Question.Title>
        Para las próximas tres preguntas, piensa en tu clase de ciencias.
      </Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            Para las próximas tres preguntas, piensa en tu clase de{' '}
            <u>ciencia</u>. La ciencia incluye materias como biología, química,
            física y Ciencias fisicas.
          </p>
          <p>
            Si tomas más de una clase de ciencias, piensa en <u>todas</u> de tus
            clases de ciencias.
          </p>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),
};

const ScienceIntroduction = () => (
  <LocaleSwitch label="sesStudent23ScienceIntro" messages={messages} />
);

export default ScienceIntroduction;
