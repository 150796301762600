import { sample } from 'lodash';
import { getProgramMetricConfig } from '@perts/config';
import { ResponseDoc } from 'index.d';

export const transformResponse = (response: ResponseDoc): ResponseDoc => {
  const { surveyLabel } = response;
  const metricConfig = getProgramMetricConfig(surveyLabel);

  if (!metricConfig) {
    // The requested survey does not have a config.
    return response;
  }

  // Neptune portal will provide learning conditions to Saturn using the
  // `open_response_lcs` query param. At the time a new response is created
  // we will choose one question from each of these selected learning
  // conditions to display an open response question for.
  const showOpenResponses = metricConfig.metrics.reduce<string[]>(
    (acc, metric) => {
      if (response.meta?.open_response_lcs?.includes(metric.label)) {
        const randomOpenResponse = `or_${sample(metric.items)?.label}`;
        return [...acc, randomOpenResponse];
      }

      return acc;
    },
    [],
  );

  return {
    ...response,
    meta: {
      ...response.meta,
      showOpenResponses,
    },
  };
};
