import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Pages } from 'components';
import { transformConfigToPages } from './transformConfigToPages';

import { ascend23 } from 'surveys/ascend23';
import { ascend24 } from 'surveys/ascend24';
import { catalyze23 } from 'surveys/catalyze23';
import { catalyze24 } from 'surveys/catalyze24';
import { elevate23 } from 'surveys/elevate23';
import { elevate24 } from 'surveys/elevate24';
import { sesTeacher23 } from 'surveys/sesTeacher23';
import { sesStudent23 } from 'surveys/sesStudent23';

export const surveys = {
  ascend23,
  ascend24,
  catalyze23,
  catalyze24,
  elevate23,
  elevate24,
  sesTeacher23,
  sesStudent23,
};

const surveyLoader = (surveyLabel) => {
  if (!(surveyLabel in surveys)) {
    return null;
  }

  const survey = surveys[surveyLabel];
  return transformConfigToPages(survey);
};

// Component to display while the survey config is loading.
const SurveyLoading = () => (
  <div>
    <FormattedMessage id="ui.survey.loading" />
  </div>
);

class SurveyLoader extends React.Component {
  // Using a React.Component vs React.FC and useState hook because React would
  // not render the SurveyComponent component when provided to useState.
  constructor() {
    super();
    this.state = {
      isLoading: true,
      pages: [],
    };
  }

  async componentDidMount() {
    const { surveyLabel } = this.props.match.params;
    const pages = surveyLoader(surveyLabel);
    this.setState({ isLoading: false, pages });
  }

  render() {
    if (this.state.isLoading) {
      return <SurveyLoading />;
    }

    if (!this.state.pages) {
      return (
        <h2 data-test="MsgSurveyDoesNotExist">
          <FormattedMessage id="ui.survey.error.badSurveyCode" />
        </h2>
      );
    }

    return <Pages pages={this.state.pages} />;
  }
}

export default withRouter(SurveyLoader);
