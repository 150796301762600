import { merge } from 'lodash';
import { SurveyConfig } from 'types';

export const transformConfigToPages = (config: SurveyConfig) => {
  const pages = config.survey
    .flatMap((element) => {
      if (element.component === 'Page') {
        return element;
      }

      if (element.component === 'Block') {
        return element.pages.map((page, pageIndex) => {
          // The Page inherits `props` from parent Block.
          // Page `props` specified in config override Block `props`.
          const props = merge({}, element.props || {}, page.props || {});

          return {
            ...page,
            label: `${element.label}${pageIndex + 1}`,
            props,
          };
        });
      }

      return null;
    })
    .filter(Boolean);

  return pages;
};
