import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { PathParams, ResponseDocUpdateable, SurveyAnswers } from 'index.d';
import { updateResponse } from 'services';
import { useResponseContext } from 'services/responseContext';
import { validationSchema } from 'surveys';
import {
  ContextReminder,
  Debug,
  PageNavigation,
  PageTransition,
  RenderQuestion,
  UpdateParticipantData,
} from 'components';
import UpdateParticipantDataProgress from 'components/UpdateParticipantDataProgress';

import { useKeyNextPage } from './useKeyNextPage';
import { useLogEventComplete } from './useLogEventComplete';
import { useRouteNext } from './useRouteNext';
import { useUpdateResponseProgressValue } from './useUpdateResponseProgressValue';
import { SurveyPage } from 'types';

export type Props = {
  page: SurveyPage;
  next?: string;
};

const Page = ({ page, next }: Props) => {
  const { surveyLabel, responseId } = useParams<PathParams>();
  const response = useResponseContext();

  const baseRoute = `/surveys/${surveyLabel}/${responseId}`;
  const nextRoute = next && `${baseRoute}/${next}`;
  const { routeNext, visible, setNextRoute } = useRouteNext(page, nextRoute);

  const { progress, props } = page;
  const { waitBeforeNext, waitBeforeNextText } = props || {};
  let { contextReminderProp, contentReminderMessageKey } = props || {};

  // To allow for displaying ContextReminder on one Question but not the other
  // within the same Page (see https://github.com/perts/perts/issues/1977),
  // Question contextReminder related props override Page level props.
  contextReminderProp =
    visible.props.contextReminderProp || contextReminderProp;
  contentReminderMessageKey =
    visible.props.contentReminderMessageKey || contentReminderMessageKey;

  // Set up Ctrl-Y next page keyboard shortcut.
  const bindSubmitForm = useKeyNextPage();

  // Update progress.
  useUpdateResponseProgressValue(responseId, progress);
  useLogEventComplete(surveyLabel, progress);

  const onClickNext = (answers: SurveyAnswers): Promise<void> =>
    // Like redux-forms, to properly track `isSubmitting` status, the `onSubmit`
    // handler should return a promise that resolves/rejects.
    new Promise((resolve, reject) => {
      const updatedResponse: ResponseDocUpdateable = {
        ...(answers ? { answers } : {}),
        ...(next ? { page: next } : {}),
      };

      updateResponse(responseId as string, updatedResponse)
        .then((success) => {
          routeNext();
          // eslint-disable-next-line no-console
          console.debug(`Response update successful. ${success}`);
          resolve();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.debug('Response update error.', err);
          reject(err);
        });
    });

  const initialValues = {
    // For student privacy, we never display a user's previous answers.
    //
    // TODO If we don't set all of the field names here in initialValues, then
    // React reports a warning: `A component is changing an uncontrolled input
    // of type text to be controlled.` But we don't want initialValues and we
    // don't know all of the possible fields names ahead of time since this is
    // set in the `surveys/{programName}/` config. The form still seems to work,
    // so leaving this as a TODO to come back to.
  };

  return (
    <PageTransition className="Page">
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          await onClickNext(values);

          // NOTE: When there is more than a single question on a page, we
          // are not resetting the form to allow for follow up questions. This
          // is breaking our rule on student survey privacy, so make sure to be
          // careful about what data is displayed in the follow up question.

          if (page.questions.length <= 1) {
            // Reset form so user never sees previously submitted answers.
            actions.resetForm();
          }
        }}
        validationSchema={validationSchema}
      >
        {({ errors, handleSubmit, isSubmitting, submitForm, values }) => {
          bindSubmitForm(submitForm);

          return (
            <Form>
              {contextReminderProp && (
                <ContextReminder
                  messageKey={contentReminderMessageKey}
                  metaProp={contextReminderProp}
                />
              )}

              <RenderQuestion
                questions={page.questions}
                visibleQuestion={visible}
                routeNext={routeNext}
                setNextRoute={setNextRoute}
              />

              {/*
              In addition to updating our response document's response value,
              which happens in a `useEffect` above, we also need to update the
              value in Neptune's ParticipantData table.
            */}
              <UpdateParticipantDataProgress progress={progress} />

              {page.updateParticipantData && (
                <UpdateParticipantData
                  pdKey={page.updateParticipantData.pdKey}
                  pdValue={page.updateParticipantData.pdValue}
                />
              )}

              <PageNavigation
                waitBeforeNext={waitBeforeNext}
                waitBeforeNextText={waitBeforeNextText}
                disabled={isSubmitting || !isEmpty(errors)}
                submitting={isSubmitting}
                next={next}
                onClickNext={() => {
                  handleSubmit();
                }}
              />

              <Debug>{JSON.stringify(values, null, 2)}</Debug>
              <Debug>{JSON.stringify(errors, null, 2)}</Debug>
              <Debug>{JSON.stringify(response, null, 2)}</Debug>
            </Form>
          );
        }}
      </Formik>
    </PageTransition>
  );
};

export default Page;
