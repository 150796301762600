// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! //
//
//     NOT THE REAL SURVEY!
//
//     Only used by RServe end-to-end tests
//
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! //

import transform from './transform';
// import validationSchema from './validationSchema';
import whenLearningCondition from '../../utils/whenLearningCondition';
import sawDemographics from '../../utils/sawDemographics';

// TODO figure out how to get imports from imported files working.
// import { Block, Page, Question, LikertScale } from 'components';
const Block = () => null;
const Page = () => null;
const Question = () => null;
const LikertScale = () => null;
const Checkbox = () => null;
const Radio = () => null;
const TextField = () => null;

// likertNOptions(7) >> ['1', '2', '3', '4', '5', '6', '7']
const likertNOptions = (n) => {
  const arr = new Array(n);
  for (let i = 0; i < arr.length; i += 1) {
    arr[i] = { value: `${i + 1}`, label: 'TODO add labels' };
  }
  return arr;
};

const checkboxBooleanOptions = () => [
  {
    value: true,
    label: '',
  },
  {
    value: false,
    label: '',
  },
];

const mockBlockQuestionsLikert = (
  label,
  nextLabel,
  likertN,
  numberOfQuestions,
) => {
  const pages = Array.from({ length: numberOfQuestions }, (_, index) => {
    const page = index + 1;

    const pageLabel = `${label}${page}`;
    const nextPageLabel =
      page < numberOfQuestions ? `${label}${page + 1}` : `${nextLabel}1`;

    return {
      component: Page,
      label: pageLabel,
      next: nextPageLabel,
      children: [
        {
          component: Question,
          componentRender: LikertScale,
          name: pageLabel.replace('-', '_'),
          label: '',
          likertN,
          answerOptions: likertNOptions(likertN),
        },
      ],
    };
  });

  return {
    component: Block,
    label,
    showWhen: whenLearningCondition(label),
    waitBeforeNext: true,
    children: pages,
  };
};

// NOTE
// - learning condition separator is `-` (hyphen)
// - question label separator is `_` (underscore)

export const surveyLabel = 'catalyze21';

export const learningConditions = [
  'collective-vision',
  'inclusive-empowerment',
  'learning-culture',
  'supportive-leadership',
  'transformative-equity',
  'trusting-community',
];

export const defaultMyClassName = 'my class';
export const defaultYourClassName = 'your class';

export default () => ({
  label: surveyLabel,
  learningConditions,
  validationSchema: {},
  transform,
  firstPage: 'introduction',
  questions: [
    {
      component: Block,
      label: 'introduction',
      showWhen: () => true,
      waitBeforeNext: true,
      children: [
        {
          component: Page,
          label: 'introduction',
          next: 'collective-vision1',
          progress: 1,
          children: [],
        },
      ],
    },
    mockBlockQuestionsLikert(
      learningConditions[0],
      learningConditions[1],
      6,
      5,
    ),
    mockBlockQuestionsLikert(
      learningConditions[1],
      learningConditions[2],
      6,
      5,
    ),
    mockBlockQuestionsLikert(
      learningConditions[2],
      learningConditions[3],
      6,
      4,
    ),
    mockBlockQuestionsLikert(
      learningConditions[3],
      learningConditions[4],
      6,
      5,
    ),
    mockBlockQuestionsLikert(
      learningConditions[4],
      learningConditions[5],
      6,
      5,
    ),
    mockBlockQuestionsLikert(learningConditions[5], 'fidelity', 6, 5),
    {
      component: Block,
      label: 'fidelity',
      waitBeforeNext: true,
      children: [
        {
          component: Page,
          label: 'fidelity1',
          next: 'fidelity2',
          children: [
            {
              component: Question,
              componentRender: LikertScale,
              name: 'fidelity_school_better',
              label: '',
              likertN: 5,
              answerOptions: likertNOptions(5),
            },
          ],
        },
        {
          component: Page,
          label: 'fidelity2',
          next: 'demographics1',
          children: [
            {
              component: Question,
              componentRender: Radio,
              name: 'fidelity_honest',
              label: 'Do you feel comfortable answering...',
              answerOptions: [
                {
                  value: 1,
                  label: 'Yes',
                },
                {
                  value: 0,
                  label: 'No',
                },
              ],
              // Note: Placing this here, as a child of the Question, to make
              // it easier to traverse for rserve_test. It won't be rendered
              // as a child component to the Question, so this might move.
              linkedQuestion: {
                component: Question,
                componentRender: TextField,
                name: 'fidelity_why_not',
                label: 'Why not?',
                linkedFieldName: 'fidelity_honest',
                linkedFieldTest: '0',
              },
            },
          ],
        },
      ],
    },
    {
      component: Block,
      label: 'demographics',
      hideWhen: sawDemographics,
      waitBeforeNext: true,
      children: [
        {
          component: Page,
          label: 'demographics1',
          next: 'demographics2',
          children: [
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_amindian',
              label: 'American Indian or Alaskan Native',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_e_asian',
              label: 'East Asian (e.g., Chinese, Japanese, Korean, Taiwanese)',
              answerOptions: checkboxBooleanOptions(),
            },

            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_se_asian',
              label: 'Southeast Asian (e.g., Filipino, Indonesian, Vietnamese)',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_s_asian',
              label:
                'South Asian (e.g., Pakistani, Indian, Nepalese, Sri Lankan)',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_o_asian',
              label: 'Other Asian',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_mex',
              label: 'Mexican American/Chicano',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_puerto_rican',
              label: 'Puerto Rican',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_central_american',
              label: 'Central American',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_o_latino',
              label: 'Other Hispanic or Latinx',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_african_american',
              label: 'African American/Black',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_african',
              label: 'African',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_caribbean',
              label: 'Caribbean',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_o_black',
              label: 'Other Black',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_european',
              label: 'European/European American',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_middle_eastern',
              label: 'Middle Eastern/Middle Eastern American',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_o_white',
              label: 'Other White',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_pac_isl',
              label: 'Native Hawaiian or Other Pacific Islander',
              answerOptions: checkboxBooleanOptions(),
            },
            {
              component: Question,
              componentRender: Checkbox,
              name: 'race_other',
              label: 'Other Group (Please specify)',
              answerOptions: checkboxBooleanOptions(),
              linkedQuestion: {
                component: Question,
                componentRender: TextField,
                name: 'race_text',
                linkedFieldName: 'race_other',
                linkedFieldTest: 'true',
              },
            },
          ],
        },
        {
          component: Page,
          label: 'demographics2',
          next: 'thankyou',
          children: [
            {
              component: Question,
              componentRender: Radio,
              name: 'gender',
              answerOptions: [
                {
                  value: 'female',
                  label: 'Female',
                },
                {
                  value: 'male',
                  label: 'Male',
                },
                {
                  value: 'other',
                  label: 'Non-Binary/Other',
                },
                {
                  value: 'opt_out',
                  label: 'Prefer not to answer',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      component: Block,
      label: 'completion',
      waitBeforeNext: true,
      children: [
        {
          component: Page,
          label: 'thankyou',
          children: [],
        },
      ],
    },
  ],
});
