import { LocaleSwitch, Question, TextToSpeechContent } from 'components';
import { locales } from 'i18n';

const messages = {
  [locales.ENGLISH]: () => (
    <>
      <Question.Title>Your opinion matters!</Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            This short survey is for you to provide feedback for staff at your
            school to learn about your experiences as a student here. Nobody
            from your school will see your name in connection with any of your
            answers. Please answer each question as honestly as you can. Your
            honest feedback is important, and there are no right or wrong
            answers.
          </p>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),

  [locales.SPANISH]: () => (
    <>
      <Question.Title>¡Tu opinión importa!</Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            Esta breve encuesta es para que brinde comentarios al personal de su
            escuela para aprender sobre sus experiencias como estudiante aquí.
            Nadie de su escuela verá su nombre en relación con cualquiera de sus
            respuestas Por favor conteste cada pregunta tan honestamente como
            pueda. Su la retroalimentación honesta es importante, y no hay
            buenas o malas respuestas
          </p>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),
};

const SurveyIntroduction = () => (
  <LocaleSwitch label="sesStudent23SurveyIntroduction" messages={messages} />
);

export default SurveyIntroduction;
