import { SurveyConfig } from 'types';
import blockFromMetricsConfig from 'utils/blockFromMetricsConfig';

import blockFidelitySchool from 'surveys/common/block-fidelity-school';
import blockDemographicsRaceGender from 'surveys/common/block-race-gender23';

import SurveyIntroduction from './SurveyIntroduction';
import SurveyComplete from './SurveyComplete';

export const catalyze23: SurveyConfig = {
  name: 'Catalyze',
  label: 'catalyze23',
  survey: [
    {
      component: 'Page',
      label: 'introduction',
      progress: 1,
      props: {
        waitBeforeNext: true,
      },
      questions: [
        {
          label: 'intro_your_opinion',
          component: SurveyIntroduction,
          props: {},
        },
      ],
    },

    blockFromMetricsConfig(
      'catalyze23',
      'effective-systems',
      'Effective Systems',
      'effective-systems',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze23',
      'collective-vision',
      'Collective Vision',
      'collective-vision',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze23',
      'trusting-community',
      'Trusting Community',
      'trusting-community',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze23',
      'learning-culture',
      'Learning Culture',
      'learning-culture',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze23',
      'inclusive-leadership',
      'Inclusive Leadership',
      'inclusive-leadership',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze23',
      'transformative-equity',
      'Transformative Equity',
      'transformative-equity',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,

    blockFidelitySchool,
    blockDemographicsRaceGender,
    {
      component: 'Page',
      label: 'thankyou',
      progress: 100,
      updateParticipantData: {
        pdKey: 'saw_demographics',
        pdValue: 'true',
      },
      questions: [
        {
          label: 'surveycomplete',
          component: SurveyComplete,
          props: {},
        },
      ],
    },
  ],
};
