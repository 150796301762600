import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LocaleContext } from 'i18n';
import { getResponseAndListen } from 'services';
import { ResponseContext } from 'services/responseContext';
import SurveyLoader from 'scenes/SurveyLoader';
import {
  DisplayPreviewWarning,
  LocaleSelector,
  SetSentryContext,
  SurveyHeader,
  TextToSpeechToggle,
} from 'components';

const Survey = () => {
  const history = useHistory();
  const { surveyLabel, responseId } = useParams();
  const [response, setResponse] = useState({});
  const [state, setState] = useState('LOADING');
  const { locale, setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const unsubscribeFn = getResponseAndListen(
      responseId,
      (responseDocData) => {
        setResponse(responseDocData);
        setState('SUCCESS');

        // Update locale (in context) based on response setting.
        if (locale !== responseDocData.locale) {
          setLocale(responseDocData.locale);
        }

        // Place response on window during Cypress testing.
        // See the Learning Conditions > Open Response test for example usage.
        if (window.Cypress) {
          window.PERTS = window.PERTS || {};
          window.PERTS.response = responseDocData;
        }
      },
      () => setState('ERROR'),
    );

    // Unsubscribe from Firestore when we unmount.
    // https://reactjs.org/docs/hooks-effect.html#effects-with-cleanup
    return unsubscribeFn;
  }, [locale, responseId, setLocale]);

  useEffect(() => {
    // Redirect to `response.surveyLabel` if it doesn't match route.
    // https://github.com/PERTS/saturn/issues/25
    if (response && response.surveyLabel) {
      if (response.surveyLabel !== surveyLabel) {
        history.replace(`/surveys/${response.surveyLabel}/${responseId}`);
      }
    }
  }, [history, surveyLabel, response, responseId]);

  if (state === 'LOADING') {
    return (
      <div>
        <FormattedMessage id="ui.survey.loading" />
      </div>
    );
  }

  if (state === 'ERROR') {
    return (
      <div>
        <FormattedMessage id="ui.survey.error.badResponseId" />
      </div>
    );
  }

  return (
    <div className="Survey">
      <ResponseContext.Provider value={response}>
        <SurveyHeader>
          <TextToSpeechToggle />
          <LocaleSelector />
        </SurveyHeader>
        <DisplayPreviewWarning />
        <SetSentryContext response={response} />
        <SurveyLoader />
      </ResponseContext.Provider>
    </div>
  );
};

Survey.propTypes = {
  path: PropTypes.string,
  surveyLabel: PropTypes.string,
  responseId: PropTypes.string,
};

export default Survey;
