import { FieldProps } from 'formik';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  FlexItem,
  FlexRow,
  RadioWithLabel,
  UnorderedList,
  ListItem,
  theme,
} from 'perts-ui';
import {
  Field,
  Question,
  TextToSpeechButton,
  TextToSpeechContent,
} from 'components';

const Legalese = styled.div`
  height: 200px;
  overflow-y: scroll;
  border: 1px solid ${theme.colors.grayMedium};
  padding: 10px;
  margin: 10px 0;
`;

const Heading1 = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  margin: 8px 0;
`;

const Heading2 = styled.h2`
  text-align: center;
  font-weight: bold;
  margin: 5px 0;
`;

const Strong = styled.strong`
  font-weight: bold;
`;

const yesValue = 'yes';
const noValue = 'no';

type Props = { label: string; setNextRoute: Function } & FieldProps;

export const Consent = (props: Props) => {
  const { responseId, surveyLabel } = useParams() as any;
  const { setNextRoute } = props;
  const { onChange, name, value } = props.field;
  const { isSubmitting } = props.form;

  const handleChange = (newValue: string) => {
    // Branch the survey flow based on their answer to the current question.
    // Set the next route, which will be used on form submission.
    // If they consent, go to the first question. NOTE: this must be manually
    // kept in sync with the overall survey flow in sesTeacher23/index.ts.
    // If they do NOT consent, go to the end of the survey, where they will be
    // marked as having progress 100.
    const nextPage =
      newValue === yesValue ? 'faculty-mindset-beliefs1' : 'thankyou';
    setNextRoute(`/surveys/${surveyLabel}/${responseId}/${nextPage}`);
    onChange({
      target: {
        name,
        value: newValue,
      },
    });
  };

  return (
    <>
      <Question.Title>The Student Experience Study</Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <Heading1>The Student Experience Study</Heading1>

          <Heading2>
            The Project for Education Research that Scales (PERTS)
          </Heading2>
        </TextToSpeechContent>
        <TextToSpeechContent>
          <Legalese>
            <p>
              The Project for Education Research That Scales (PERTS) is a
              nonprofit research and development institute that translates
              insights from psychological science into cutting-edge tools,
              measures, and recommendations that educators anywhere can use to
              foster healthy and equitable academic engagement and success
              (perts.net).
            </p>

            <p>
              PERTS is conducting a research study to understand the impact
              educators can have by centering student experience. Participation
              is completely voluntary and will not affect your employment in any
              way. We are asking you to consider being a part of this study
              because your school is using the Elevate platform, developed by
              PERTS. You may participate in this research even if you do not
              plan to use Elevate this year. We anticipate about 185 educators
              to participate in this study. If you agree to be in the study,
              your participation will take approximately 15 minutes per
              activity.
            </p>

            <p>
              As part of this study, all participants will be asked to complete
              two surveys: one at the beginning of the school year and another
              survey at the end of the school year.{' '}
              <Strong>
                You will receive a $50 gift card at the end of the school year
              </Strong>{' '}
              for completing both surveys.
            </p>

            <p>
              <Strong>Risks:</Strong> Participation in the study poses minimal
              risk beyond the physical or psychological harm that is normally
              encountered in the daily lives of healthy persons. As with any
              research study, there is the risk of loss of confidentiality of
              your personal information collected for this study. While PERTS
              uses rigorous methods to maintain the confidentiality of research
              data, we can never completely eliminate this risk.
            </p>

            <p>
              <Strong>Benefits:</Strong> There are no guaranteed benefits to you
              as a result of your participation in this study, but we hope that
              the data collected will add to the knowledge about how educators
              can impact student learning by centering student experiences.
            </p>

            <p>
              <Strong>Alternatives:</Strong> An alternative to participating in
              this research is to not complete the surveys.
            </p>

            <p>
              Now we will review some additional details about this research
              study.
            </p>

            <UnorderedList>
              <ListItem>There will be no costs to you.</ListItem>
              <ListItem>Your participation is completely voluntary.</ListItem>
              <ListItem>
                You may discuss this study with your colleagues, family, and
                friends before you decide whether you would like to participate.
              </ListItem>
            </UnorderedList>

            {/* just for spacing */}
            <br />

            <p>
              If you do not take part in the study or decide to discontinue your
              participation, there will be no penalty to you. If you don’t take
              part, it won’t affect the benefits to which you are entitled. If
              those conducting the study think it’s not in your best interest to
              continue or decide to stop the study, we will end your
              participation.
            </p>

            <p>
              Once you provide permission, it will not expire. But, you can
              withdraw from the study or cancel your permission for us to use
              your information at any time by writing to the research team at{' '}
              <a href="mailto:research@perts.net">research@perts.net</a>. You
              may also skip individual survey questions if you prefer not to
              answer them. If you decide you don’t want your information used,
              or if you have any questions or complaints, you may contact a
              person not on the research team at the Biomedical Research
              Alliance of New York Institutional Review Board at (516) 318-6877
              or at{' '}
              <a href="https://www.brany.com/report-concerns/">
                www.branyirb.com/concerns-about-research
              </a>
              . Information that was already collected at the time of your
              withdrawal may still be used.
            </p>

            <p>
              Please feel free to print this screen or take a screenshot to save
              it for your records.
            </p>

            <p>
              If you have any questions about the information in this document,
              please contact{' '}
              <a href="mailto:research@perts.net">research@perts.net</a> to get
              in touch with the PERTS research team.
            </p>
          </Legalese>
        </TextToSpeechContent>
        <TextToSpeechContent>
          <p>Are you interested in participating in this study at this time?</p>
        </TextToSpeechContent>
        <FlexRow>
          <FlexItem>
            <RadioWithLabel
              value={yesValue}
              label="Yes, I would like to participate!"
              name={name}
              disabled={isSubmitting}
              checked={value === yesValue}
              onChange={() => handleChange(yesValue)}
            />
          </FlexItem>

          <FlexItem grow={0}>
            <TextToSpeechButton text="Yes, I would like to participate!" />
          </FlexItem>
        </FlexRow>

        <FlexRow>
          <FlexItem>
            <RadioWithLabel
              value={noValue}
              label="No, thank you."
              name={name}
              disabled={isSubmitting}
              checked={value === noValue}
              onChange={() => handleChange(noValue)}
            />
          </FlexItem>

          <FlexItem grow={0}>
            <TextToSpeechButton text="No, thank you." />
          </FlexItem>
        </FlexRow>
      </Question.Content>
    </>
  );
};

const QuestionConsent = ({ label, ...rest }: any) => (
  <Field name={label} label={label} component={Consent} {...rest} />
);

export default QuestionConsent;
