import { useHistory, useParams } from 'react-router-dom';
import { useResponseContext } from 'services/responseContext';
import { Page, UpdateParticipantData } from 'components';
import { PathParams, ResponseDocContextValue } from 'index.d';
import { SurveyPage, WhenFunction } from 'types';

type Props = {
  pages: SurveyPage[];
};

const showWhenDefault = () => true;
const hideWhenDefault = () => false;

const should = (
  opOrOps: WhenFunction | WhenFunction[],
  opOrOpsArrayFn: 'every' | 'some',
  response: ResponseDocContextValue,
) => {
  if (Array.isArray(opOrOps)) {
    return opOrOps[opOrOpsArrayFn]((o) => o(response));
  }

  return opOrOps(response);
};

const Pages = ({ pages }: Props) => {
  const history = useHistory();
  const { surveyLabel, responseId, pageLabel } = useParams<PathParams>();
  const response = useResponseContext();

  // Annotate pages with `showWhen` flag, which is used when determining which
  // page to display when the user clicks Next.
  for (const p of pages) {
    const showWhen = p.props?.showWhen || showWhenDefault;
    const hideWhen = p.props?.hideWhen || hideWhenDefault;

    // Determine if this page should be displayed. If not, redirect to next page.
    // If array provided, show when every showWhen is true.
    const shouldShow = should(showWhen, 'every', response);
    // If array provided, hide when any hideWhen is true.
    const shouldHide = should(hideWhen, 'some', response);

    // To avoid confusing logic, don't allow both showWhen and hideWhen
    if (showWhen !== showWhenDefault && hideWhen !== hideWhenDefault) {
      throw Error(
        "You can't use both showWhen and hideWhen. Use one or the other.",
      );
    }

    p.props = p.props || {};
    p.props.shouldShow = !(!shouldShow || shouldHide);
  }

  // First and last page
  const firstPage = pages[0].label;
  const lastIndex = pages.length - 1;

  // Current page
  const pageIndex = pages.findIndex((p) => p.label === pageLabel);
  const page = pages[pageIndex];

  const isLastPage = pageIndex === lastIndex;

  // Next page
  let nextIndex = pageIndex;
  let nextPage;
  let nextPageLabel;

  if (!isLastPage) {
    nextIndex = pageIndex + 1;
    nextPage = pages[nextIndex];
    nextPageLabel = nextPage.label;

    let isNextLastPage = nextIndex === lastIndex;

    while (!isNextLastPage && !nextPage.props?.shouldShow) {
      nextIndex = nextIndex + 1;
      nextPage = pages[nextIndex];
      nextPageLabel = nextPage.label;

      isNextLastPage = nextIndex === lastIndex;
    }
  }

  if (!pageLabel) {
    if (response && response.page) {
      // If this response has recorded some previous progress through the survey
      // then redirect the user to the last recorded page.
      history.replace(`/surveys/${surveyLabel}/${responseId}/${response.page}`);
    } else {
      // Use `replace` and not `push` so that the user doesn't come back to this
      // route on a browser back press and just get redirected to the firstPage
      // again. This will make it difficult for the user to actually go back to
      // where they probably want to go.
      history.replace(`/surveys/${surveyLabel}/${responseId}/${firstPage}`);
    }

    return null;
  }

  // When the participant gets to the first page of the survey, we want to
  // save the responseId to Neptune's ParticipantData table.
  const updateParticipantDataResponseId = pageLabel === firstPage;

  return (
    <div className="Pages">
      <Page page={page} next={nextPageLabel} />

      {updateParticipantDataResponseId && typeof responseId === 'string' && (
        <UpdateParticipantData pdKey="responseId" pdValue={responseId} />
      )}
    </div>
  );
};
export default Pages;
