import { SurveyBlock } from 'types';
import QuestionRace from '../block-race-gender23/QuestionRace';
import QuestionRaceFollowup from '../block-race-gender23/QuestionRaceFollowup';
import QuestionGender from '../block-race-gender23/QuestionGender';
import sawDemographics from 'utils/sawDemographics';

export const config = {
  label: 'demographics-race-gender',
  name: 'Demographics Race/Gender',
};

const block: SurveyBlock = {
  component: 'Block',
  label: 'demographics',
  props: {
    hideWhen: sawDemographics,
    waitBeforeNext: true,
  },
  pages: [
    {
      component: 'Page',
      questions: [
        {
          component: QuestionRace,
          label: 'race',
          props: {},
        },
        {
          component: QuestionRaceFollowup,
          label: 'racefollowup',
          props: {},
        },
      ],
    },
    {
      component: 'Page',
      questions: [
        {
          component: QuestionGender,
          label: 'gender',
          props: {},
        },
      ],
    },
  ],
};

export default block;
