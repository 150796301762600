import blockFromMetricsConfig from 'utils/blockFromMetricsConfig';

const { config, block } = blockFromMetricsConfig(
  'elevate23',
  'meaningful-work',
  'Meaningful Work',
  'mw',
  {
    contextReminderProp: 'class_name',
    waitBeforeNext: true,
  },
);

export { config, block as default };
