import { useState } from 'react';
import { getLocaleConfigByTag, useLocaleContext } from 'i18n';

const useSpeech = (text: string) => {
  const [speaking, setSpeaking] = useState(false);
  const { locale } = useLocaleContext();
  const localeConfig = getLocaleConfigByTag(locale);

  // https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesisUtterance
  const utter = new SpeechSynthesisUtterance(text);

  if (localeConfig.voices) {
    for (const preferredVoice of localeConfig.voices) {
      const localeVoice = window.speechSynthesis
        .getVoices()
        .find(
          (v) =>
            preferredVoice.lang.test(v.lang) &&
            (!preferredVoice.name || preferredVoice.name === v.name),
        );

      if (localeVoice) {
        utter.voice = localeVoice;
        break;
      }
    }
  }

  let speak: Function = () => null;
  let cancel: Function = () => null;

  if (window.speechSynthesis) {
    const synth = window.speechSynthesis;
    speak = () => {
      setSpeaking(true);
      synth.speak(utter);
    };

    cancel = () => {
      setSpeaking(false);
      synth.cancel();
    };

    // https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesisUtterance/end_event
    utter.onend = () => setSpeaking(false);
  }

  return { speak, cancel, speaking };
};

export default useSpeech;
