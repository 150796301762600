// Wrap children so that a TextToSpeechButton appears when text-to-speech has
// been enabled.

import React from 'react';
import { FlexItem, FlexRow } from 'perts-ui';
import { TextToSpeechButton } from 'components';

const TextToSpeechContent = ({ children }) => (
  <FlexRow>
    <FlexItem>{children}</FlexItem>
    <FlexItem grow={0}>
      <TextToSpeechButton text={children} />
    </FlexItem>
  </FlexRow>
);

export default TextToSpeechContent;
