import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Card } from 'perts-ui';
import { TextToSpeechButton } from 'components';

const QuestionTitleStyled = styled(Card.Title)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-size: 14pt;

  /*
    Workaround to eliminate the height increase that TextToSpeechButton causes.
  */
  button {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  /* Make sure long title text doesn't get too close to the button. */
  > * {
    margin-right: 10px;
  }
`;

const QuestionTitle = ({ children }) => (
  <QuestionTitleStyled data-test="QuestionTitle">
    <span>{children}</span> <TextToSpeechButton size={32} text={children} />
  </QuestionTitleStyled>
);

QuestionTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default QuestionTitle;
