import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useResponseContext } from 'services/responseContext';
import { updateResponse } from 'services';
import { Toggle } from 'perts-ui';
import { PathParams } from 'index.d';

const TextToSpeechToggle: React.FC = () => {
  const response = useResponseContext();
  const { responseId } = useParams<PathParams>();

  if (!window.speechSynthesis) {
    return null;
  }

  if (!responseId || !response) {
    return null;
  }

  const { textToSpeech } = response;
  const label = textToSpeech ? (
    <FormattedMessage id="ui.textToSpeech.on" />
  ) : (
    <FormattedMessage id="ui.textToSpeech.off" />
  );

  return (
    <Toggle
      name="toggleTextToSpeech"
      label={label}
      labelWidth={120}
      checked={Boolean(textToSpeech)}
      onChange={() =>
        updateResponse(responseId, { textToSpeech: !textToSpeech })
      }
    />
  );
};

export default TextToSpeechToggle;
