import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const FieldSetStyled = styled.fieldset`
  border: 0;
  padding: 0;
`;

const FieldSet = ({ name = '', className, children }) => {
  const cx = classNames(className);

  return (
    <FieldSetStyled name={name} className={cx}>
      {children}
    </FieldSetStyled>
  );
};

FieldSet.propTypes = {
  // To allow styled-components wrapping.
  className: PropTypes.string,

  // FieldSet content.
  children: PropTypes.any,
};

export default FieldSet;
