import styled from 'styled-components/macro';

const LocaleSelectorStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  select {
    padding: 8px 8px 8px 16px;
    font-size: 16px;
  }
`;

export default LocaleSelectorStyled;
