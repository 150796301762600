import React from 'react';
import { Card } from 'perts-ui';
import QuestionTitle from './QuestionTitle';

const Question = ({ children, title = '', ...props }) => {
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, props),
  );

  if (title) {
    return (
      <Card {...props}>
        <QuestionTitle>{title}</QuestionTitle>
        {childrenWithProps}
      </Card>
    );
  }

  return <Card children={childrenWithProps} {...props} />;
};

Question.Title = QuestionTitle;
Question.Title.displayName = 'Question.Title';

Question.Content = Card.Content;
Question.Content.displayName = 'Question.Content';

export default Question;
