import styled from 'styled-components';
import {
  ImageStar,
  LocaleSwitch,
  Question,
  TextToSpeechContent,
} from 'components';
import { locales } from 'i18n';

const SurveyCompleteStyled = styled.div`
  margin-left: auto;
  margin-right: auto;

  text-align: center;

  > * {
    margin-top: 20px;
  }
`;

const messages = {
  [locales.ENGLISH]: () => (
    <>
      <Question.Title>Thank you!</Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <SurveyCompleteStyled>
            <p>You&rsquo;re all done.</p>
            <ImageStar />
            <p>Thank you for your feedback.</p>
          </SurveyCompleteStyled>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),
  [locales.SPANISH]: () => (
    <>
      <Question.Title>¡Gracias!</Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <SurveyCompleteStyled>
            <p>Has terminado.</p>
            <ImageStar />
            <p>Gracias por tus comentarios.</p>
          </SurveyCompleteStyled>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),
};

const SurveyComplete = () => (
  <LocaleSwitch label="sesStudent23SurveyComplete" messages={messages} />
);

export default SurveyComplete;
