import React from 'react';
import { Field } from 'formik';
import { RadioGroup } from 'components';

const QuestionDemographicsInsecureFood: React.FC = () => (
  <Field
    name="food_insecure"
    label={
      <>
        In the last 12 months, was the following situation often true, sometimes
        true, or never true for you? &ldquo;I worried whether my food would run
        out before I got money to buy more.&rdquo;
      </>
    }
    component={RadioGroup}
    options={[
      { label: 'Often True', value: 'often' },
      { label: 'Sometimes True', value: 'sometimes' },
      { label: 'Never True', value: 'never' },
    ]}
  />
);

export default QuestionDemographicsInsecureFood;
