// Helper function for `open_responses_lcs` flag sent by Neptune portal.

import { ResponseDocContextValue } from '../index.d';
import get from 'lodash/get';

const whenOpenResponse =
  (condition: string) =>
  (response: ResponseDocContextValue): boolean | null =>
    get(response, 'meta.showOpenResponses', []).includes(condition);

export default whenOpenResponse;
