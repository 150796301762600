import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import locales, { LocaleContext } from 'i18n/locales';
import { Field } from 'formik';
import { TextFieldOther } from 'components';

const label = 'fidelity_why_not';

const messages = {
  [locales.ENGLISH]: <>Why not?</>,
  [locales.SPANISH]: <>¿Por qué no?</>,
};

const QuestionFidelityHonest = ({ linkedFieldName }) => {
  const { surveyLabel } = useParams();
  const { locale } = useContext(LocaleContext);

  // When no message has been defined for the requested locale, fallback to
  // displaying the question label for development purposes.
  const questionLabel = (messages && messages[locale]) || label;

  if (questionLabel === label) {
    // If we haven't defined a message for the requested locale, it's probably a
    // mistake. Notify so we can get it added.
    console.error(
      `Question ${label} (${surveyLabel}) has no "${locale}" i18n message assigned.`,
    );
  }

  return (
    <Field
      name={label}
      component={TextFieldOther}
      label={questionLabel}
      margin="normal"
      variant="outlined"
      linkedFieldName={linkedFieldName}
      linkedFieldTest={0}
    />
  );
};

export default QuestionFidelityHonest;
