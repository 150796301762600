// Displays a reminder that the survey question displayed is in reference to
// some context, provided as a survey meta param, e.g. the classroom name.

import React from 'react';
import styled from 'styled-components';

import { LocaleSwitch, Show, TextToSpeechContent } from 'components';
import theme from 'components/theme';
import { default as messagesConfig } from 'i18n/messages';
import { useResponseContext } from 'services/responseContext';

import iconLightbulbSrc from './Lightbulb.png';
const iconWidthPx = 110;

const ContextReminderStyled = styled.div`
  margin-bottom: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  padding: 0 16px;

  min-height: 70px;

  border: 1px dashed ${theme.colors.primary};
  border-radius: ${theme.units.borderRadius};
  background: ${theme.colors.white};
  color: ${theme.colors.primary};
  font-weight: bold;
  font-size: 14pt;
`;

const ContextTextStyled = styled.div`
  padding: 8px;
  text-align: center;
  width: 100%;

  > div {
    align-items: center;
    justify-content: space-between;
  }
`;

const ContextIcon = styled.img`
  position: absolute;
  left: 20px;
  bottom: 0;

  width: ${iconWidthPx}px;
`;

const ContextIconSpacer = styled.div`
  width: ${iconWidthPx}px;
  min-width: ${iconWidthPx}px;
`;

const getMessages = (messageKey: string, contextValue = '') => {
  const messages: { [languageKey: string]: React.FC } = {};

  Object.keys(messagesConfig).forEach((lang) => {
    //  @ts-ignore
    const message = messagesConfig[lang][messageKey].replace(
      /{.*}/,
      contextValue.trim(),
    );

    messages[lang] = () => <TextToSpeechContent>{message}</TextToSpeechContent>;
  });

  return messages;
};

type Props = {
  imgAlt?: string;
  imgSrc?: string;
  metaProp: string;
  messageKey?: string;
};

const ContextReminder = ({
  imgAlt,
  imgSrc,
  messageKey = 'context.thisQuestionIsAbout',
  metaProp,
}: Props) => {
  const response = useResponseContext();
  const contextValue = response && response.meta && response.meta[metaProp];

  if (!imgAlt && imgSrc) {
    throw new Error('Must specify `imgAlt` in ContextReminder.');
  }

  imgSrc = imgSrc || iconLightbulbSrc;
  imgAlt = imgAlt || 'lightbulb';

  return (
    <Show when={contextValue}>
      <ContextReminderStyled data-testid="context-reminder">
        <ContextIconSpacer />

        <ContextTextStyled>
          <LocaleSwitch
            label="ContextReminder"
            messages={getMessages(messageKey, contextValue)}
          />
        </ContextTextStyled>

        <ContextIcon src={imgSrc} alt={imgAlt} />
      </ContextReminderStyled>
    </Show>
  );
};

export default ContextReminder;
