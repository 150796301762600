import { SurveyConfig } from 'types';
import blockFromMetricsConfig from 'utils/blockFromMetricsConfig';
import blockDemographicsRaceGender from 'surveys/common/block-race-gender23-nocontext';

import SurveyIntroductionAndConsent from './SurveyIntroductionAndConsent';
import SurveyComplete from './SurveyComplete';
import TeachingAtYourSchool from './TeachingAtYourSchool';
import TeachersStudentsRelate from './TeachersStudentsRelate';

import { surveyLabel } from './config';

export const sesTeacher23: SurveyConfig = {
  name: 'Teacher Pre/Post Survey',
  label: surveyLabel,
  survey: [
    {
      component: 'Page',
      label: 'consent',
      progress: 1,
      questions: [
        {
          label: 'consent-to-research',
          // NOTE: there are hard-coded paths in this component for where the
          // user goes based on their choice to consent.
          component: SurveyIntroductionAndConsent,
          props: {},
        },
      ],
    },

    blockFromMetricsConfig(
      surveyLabel,
      'faculty-mindset-beliefs',
      'Faculty Mindset Beliefs',
      'faculty-mindset-beliefs',
      {},
    ).block,

    {
      component: 'Page',
      label: 'teaching-at-your-school',
      questions: [
        {
          label: 'teaching-at-your-school1',
          component: TeachingAtYourSchool,
          props: {},
        },
      ],
    },

    blockFromMetricsConfig(
      surveyLabel,
      'trusting-community',
      'Trusting Community',
      'trusting-community',
      {},
    ).block,
    blockFromMetricsConfig(
      surveyLabel,
      'learning-culture',
      'Learning Culture',
      'learning-culture',
      {},
    ).block,

    {
      component: 'Page',
      label: 'teachers-students-relate',
      questions: [
        {
          label: 'teachers-students-relate1',
          component: TeachersStudentsRelate,
          props: {},
        },
      ],
    },

    blockFromMetricsConfig(
      surveyLabel,
      'perspective-taking',
      'Perspective Taking',
      'perspective-taking',
      {},
    ).block,
    blockFromMetricsConfig(
      surveyLabel,
      'relationship-efficacy',
      'Relationship Efficacy',
      'relationship-efficacy',
      {},
    ).block,
    blockFromMetricsConfig(
      surveyLabel,
      'authoritarian-discipline-beliefs',
      'Authoritarian Discipline Beliefs',
      'authoritarian-discipline-beliefs',
      {},
    ).block,
    blockFromMetricsConfig(
      surveyLabel,
      'teacher-student-voice',
      'Teacher Student Voice',
      'teacher-student-voice',
      {},
    ).block,
    blockFromMetricsConfig(
      surveyLabel,
      'teacher-burnout',
      'Teacher Burnout',
      'teacher-burnout',
      {},
    ).block,
    blockFromMetricsConfig(
      surveyLabel,
      'attitudes-towards-students',
      'Attitudes Towards Students',
      'attitudes-towards-students',
      {},
    ).block,

    blockDemographicsRaceGender,

    {
      component: 'Page',
      label: 'thankyou',
      progress: 100,
      questions: [
        {
          label: 'surveycomplete',
          component: SurveyComplete,
          props: {},
        },
      ],
    },
  ],
};
