// import React from 'react';
// const FlexItem = () => <div>FLEXITEM</div>;

import styled from 'styled-components/macro';

type Props = {
  grow?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
};

const FlexItem = styled.div<Props>`
  flex-grow: ${(props) => (props.grow == null ? 1 : props.grow)};

  margin-bottom: ${(props) => `${props.marginBottom || 0}px`};
  margin-left: ${(props) => `${props.marginLeft || 0}px`};
  margin-right: ${(props) => `${props.marginRight || 0}px`};
  margin-top: ${(props) => `${props.marginTop || 0}px`};

  padding-bottom: ${(props) => `${props.paddingBottom || 0}px`};
  padding-left: ${(props) => `${props.paddingLeft || 0}px`};
  padding-right: ${(props) => `${props.paddingRight || 0}px`};
  padding-top: ${(props) => `${props.paddingTop || 0}px`};
`;

export default FlexItem;
