import React from 'react';
import { FieldProps } from 'formik';

import { FieldSet, FlexItem, FlexRow, RadioWithLabel } from 'perts-ui';
import { Question, TextToSpeechButton } from 'components';

type RadioGroupType = {
  label: any;
  options: [{ label: any; value: any }];
  otherField: any;

  // Called when the Radio value is changed. This is the, optional,
  // user provided version of onChange, not the built-in Formik version.
  //   <Field onChange={(e) => null} component={RadioGroup} />
  onChange?: (e: { target: { name: string; value: any } }) => void;
} & FieldProps;

const RadioGroup: React.FC<RadioGroupType> = (props) => {
  const { label, options, otherField = null } = props;
  const { onChange, name, value } = props.field;
  const { isSubmitting } = props.form;

  // Transform name/value pairing to what Formik's onChange expects.
  const handleChange = (n: string, v: any) => {
    const event = {
      target: {
        name: n,
        value: v,
      },
    };
    onChange(event);
    props.onChange?.(event);
  };

  return (
    <>
      <Question.Title>{label}</Question.Title>

      <Question.Content>
        <FieldSet name={name}>
          {options.map((option) => (
            <FlexRow key={option.value}>
              <FlexItem>
                <RadioWithLabel
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  name={option.label}
                  onChange={() => handleChange(name, option.value)}
                  checked={option.value === value}
                  disabled={isSubmitting}
                />
              </FlexItem>
              <FlexItem grow={0}>
                <TextToSpeechButton text={option.label} />
              </FlexItem>
            </FlexRow>
          ))}

          {otherField}
        </FieldSet>
      </Question.Content>
    </>
  );
};

export default RadioGroup;
