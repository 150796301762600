import React from 'react';
import { FormattedMessage } from 'react-intl';
import PageNavigationStyled from './PageNavigationStyled';
import { Button } from 'perts-ui';
import { FaChevronCircleRight } from 'react-icons/fa';

type Props = {
  // Is page navigation disabled?
  disabled?: boolean;
  // Is page/form being actively submitted?
  submitting?: boolean;
  // Click handler for Next button.
  onClickNext: (event: React.TouchEvent | React.MouseEvent) => void;
};

const PageNavigationActual: React.FC<Props> = ({
  disabled = false,
  submitting = false,
  onClickNext,
}) => (
  <PageNavigationStyled className="PageNavigation">
    <Button
      type="submit"
      disabled={disabled}
      loading={submitting}
      icon={<FaChevronCircleRight />}
      onClick={onClickNext}
      data-test="ButtonNext"
    >
      <FormattedMessage id="ui.button.nextPage" />
    </Button>
  </PageNavigationStyled>
);

export default PageNavigationActual;
