import { Question, TextToSpeechContent } from 'components';

const TeachersStudentsRelate = () => (
  <>
    <Question.Title>For the next few items&hellip;</Question.Title>
    <Question.Content>
      <TextToSpeechContent>
        <p>
          The next few questions are to understand how teachers and students
          relate to each other. Please answer each question as best you can.
        </p>
      </TextToSpeechContent>
    </Question.Content>
  </>
);

export default TeachersStudentsRelate;
