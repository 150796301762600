// Binding for Formik to perts-ui TextField.

import React from 'react';
import { FieldProps } from 'formik';
import { TextField as TextFieldUi } from 'perts-ui';
import useMarkFieldSensitive from 'utils/useMarkFieldSensitive';

type Props = {
  // Whether the TextField is disabled.
  disabled?: boolean;

  // The TextField's label.
  label: string;

  // Optional, TextField placeholder text.
  placeholder?: string;
} & FieldProps;

const TextField: React.FC<Props> = (props) => {
  const { onChange, name, value = '' } = props.field;
  const { isSubmitting } = props.form;
  const error = props.form.errors[name];

  useMarkFieldSensitive(props, name);

  return (
    <TextFieldUi
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      disabled={isSubmitting}
      error={error}
      {...props}
    />
  );
};

export default TextField;
