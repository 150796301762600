import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { parse as parseQueryString } from 'query-string';
import { FormattedMessage } from 'react-intl';
import { addResponse, fbLogEvent } from 'services';

const SurveyNewAdding = () => (
  <div>
    <FormattedMessage id="ui.survey.welcome" />
  </div>
);

const SurveyNewError = () => (
  <div>
    <FormattedMessage id="ui.survey.error.generic" />
  </div>
);

const ResponsesNew = () => {
  const history = useHistory();
  const location = useLocation();
  const { surveyLabel } = useParams();
  const [state, setState] = useState('ADDING');

  // TODO validate surveyLabel from available surveys.

  if (state === 'ADDING') {
    // We want to parse any, optional, query string key/value pairs so that we
    // can store these in the responses collection document that we will create.
    const queryParams = parseQueryString(location.search);

    addResponse({ surveyLabel, meta: queryParams })
      .then((responseId) => {
        setState('SUCCESS');
        return responseId;
      })
      .then((responseId) => {
        // https://firebase.google.com/docs/analytics/events?platform=web
        fbLogEvent('survey_new', { surveyLabel });

        // Use `replace` and not `push` so that the user doesn't create another
        // new survey entry if they press the browser back button.
        history.replace(`/surveys/${surveyLabel}/${responseId}`);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.debug('Problem adding new response.', error);
        setState('ERROR');
      });
  }

  return state === 'ADDING' || state === 'SUCCESS' ? (
    <SurveyNewAdding />
  ) : (
    <SurveyNewError />
  );
};

export default ResponsesNew;
