// PageTransition is utilized by Page. It provides a single place to set the
// animation settings for our page transitions.
// See for more information: https://www.framer.com/motion/

import React from 'react';
import { motion } from 'framer-motion';

const PageTransition = ({ children, ...props }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    {...props}
  >
    {children}
  </motion.div>
);

export default PageTransition;
