import { useEffect } from 'react';
import { fbLogEvent } from 'services';

export const useLogEventComplete = (
  surveyLabel: string | undefined,
  progress: number | undefined,
) =>
  useEffect(
    function analyticsLogEventComplete() {
      if (progress === 100) {
        fbLogEvent('survey_complete', { surveyLabel });
      }
    },
    [surveyLabel, progress],
  );
