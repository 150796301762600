import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { noop } from 'lodash';
import { PathParams } from 'index.d';

export const useKeyNextPage = () => {
  const { pageLabel } = useParams<PathParams>();

  // The following sets up a keyboard shortcut to submit the form.
  // This allows us to bypass the timed button page config.
  let formikSubmitForm: Function = () => noop;
  const bindSubmitForm = (fn: Function) => (formikSubmitForm = fn);

  const submitFormOnCtrlY = useCallback((e: KeyboardEvent) => {
    if (e.ctrlKey && e.code === 'KeyY') {
      formikSubmitForm();
    }
  }, []);

  useEffect(
    function addSubmitFormKeyboardShortcut() {
      document.addEventListener('keydown', submitFormOnCtrlY);

      // Remove the event listener when we switch pages so that we don't end up
      // adding multiple event listeners that trigger multiple form submissions.
      return () => {
        document.removeEventListener('keydown', submitFormOnCtrlY);
      };
    },
    [pageLabel, submitFormOnCtrlY],
  );

  return bindSubmitForm;
};
