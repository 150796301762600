import styled from 'styled-components';
import { ImageStar, Question, TextToSpeechContent } from 'components';

const SurveyCompleteStyled = styled.div`
  margin-left: auto;
  margin-right: auto;

  text-align: center;

  > * {
    margin-top: 20px;
  }
`;

const SurveyComplete = () => (
  <>
    <Question.Title>Thank you!</Question.Title>
    <Question.Content>
      <TextToSpeechContent>
        <SurveyCompleteStyled>
          <p>You&rsquo;re all done.</p>
          <ImageStar />
          <p>Leave this screen open in case your teacher wants to check it.</p>
        </SurveyCompleteStyled>
      </TextToSpeechContent>
    </Question.Content>
  </>
);

export default SurveyComplete;
