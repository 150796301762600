import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Show } from 'components';
import PageNavigationActual from './PageNavigationActual';
import PageNavigationTimed from './PageNavigationTimed';
import { useParams } from 'react-router-dom';

const DEFAULT_WAIT_BEFORE_NEXT_TIME = 3; // seconds
const EXTEND_WAIT_BEFORE_NEXT_TIME = 3; // seconds

type Props = {
  // Optional, enforcement of time before the Next button can be pressed.
  // <PageNavigation waitBeforeNext={20}> the amount of time in seconds
  waitBeforeNext?: boolean | number;
  // Optional, text that will be displayed when the user is told to wait.
  waitBeforeNextText?: any;
  // Is page navigation disabled?
  disabled?: boolean;
  // Is page/form being actively submitted?
  submitting?: boolean;
  // Next page label.
  next?: string;
  // Click handler for Next button.
  onClickNext: (event: React.TouchEvent | React.MouseEvent) => void;
};

type TimerRef = ReturnType<typeof setTimeout> | null;

const PageNavigation: React.FC<Props> = (props) => {
  const {
    waitBeforeNext,
    waitBeforeNextText,
    disabled = false,
    submitting = false,
    next = '',
    onClickNext,
  } = props;
  const { pageLabel } = useParams<{ pageLabel: string }>();

  const [showTimedButton, setShowTimedButton] = useState<boolean>(
    Boolean(waitBeforeNext),
  );

  const timer = useRef<TimerRef>(null);

  const setTimer = (milliseconds: number) => {
    timer.current = setTimeout(() => {
      setShowTimedButton(false);
    }, milliseconds);
  };

  const clearTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  // This function sets a timer to remove the "timed" button. The timed button
  // can be used to prevent survey participants from rushing past a page. It
  // replaces the Next button with a Timed button that looks identical to the
  // Next button, but it will not take the user to the next page when clicked.
  // Instead, it will display a message that asks the user to wait.
  const displayTimedButton = useCallback(
    (milliseconds: number) => {
      if (!Boolean(waitBeforeNext)) {
        return;
      }

      // Clear existing timer.
      clearTimer();

      // Display the "timed" button.
      setShowTimedButton(true);

      // Add time to timer.
      setTimer(milliseconds);
    },
    [waitBeforeNext],
  );

  useEffect(() => {
    // Setup display of "timed" button.
    const displayTimedButtonFor =
      typeof waitBeforeNext === 'number' && waitBeforeNext > 0
        ? waitBeforeNext * 1000 // seconds
        : DEFAULT_WAIT_BEFORE_NEXT_TIME * 1000; // seconds

    displayTimedButton(displayTimedButtonFor);

    // Clean up.
    return clearTimer;
  }, [pageLabel, waitBeforeNext, displayTimedButton]);

  function onClickTimedButton() {
    // Extend the button timer by a small amount so that we ensure the "take
    // your time" message has time to be read by the user.
    displayTimedButton(EXTEND_WAIT_BEFORE_NEXT_TIME * 1000);
  }

  return (
    <Show when={next && next !== ''}>
      <Show when={showTimedButton}>
        <PageNavigationTimed
          disabled={disabled}
          pageLabel={pageLabel}
          onClickNext={onClickTimedButton}
        >
          {waitBeforeNextText}
        </PageNavigationTimed>
      </Show>

      <Show when={!showTimedButton}>
        <PageNavigationActual
          disabled={disabled}
          submitting={submitting}
          onClickNext={onClickNext}
        />
      </Show>
    </Show>
  );
};

export default PageNavigation;
