import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const TakeYourTimeStyled = styled.div`
  margin: 10px;
`;

const TakeYourTime: React.FC = ({ children }) => (
  <TakeYourTimeStyled data-test="MsgTakeYourTime" role="alert">
    {children ? children : <FormattedMessage id="ui.button.takeYourTime" />}
  </TakeYourTimeStyled>
);

export default TakeYourTime;
