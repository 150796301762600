// Find the Firebase app config objects:
// - Firebase Console
// - Project Overview Gear > Project Settings
// - General
// - Your apps > {The app you want to add}
// - Firebase SDK snippet > Config

const DEFAULT_PROJECT_ID = 'saturn-perts';

const firebaseConfig = {
  // Live / Master Branch
  'saturn-perts': {
    apiKey: 'AIzaSyAudJ2p7SbDTx8ZUe-9bJBd_gG8BOtXwis',
    authDomain: 'saturn-perts.firebaseapp.com',
    databaseURL: 'https://saturn-perts.firebaseio.com',
    projectId: 'saturn-perts',
    storageBucket: 'saturn-perts.appspot.com',
    messagingSenderId: '557127412102',
    appId: '1:557127412102:web:574b5fb49f26f74eaea407',
    measurementId: 'G-V9141VX3NG',
  },
  // Acceptance Branch
  'saturn-acceptance': {
    apiKey: 'AIzaSyAQLurGwOaN-F3z4afZcwQu8lopKZqa16M',
    authDomain: 'saturn-acceptance.firebaseapp.com',
    databaseURL: 'https://saturn-acceptance.firebaseio.com',
    projectId: 'saturn-acceptance',
    storageBucket: 'saturn-acceptance.appspot.com',
    messagingSenderId: '45828159391',
    appId: '1:45828159391:web:ce214a700761239efbefaa',
    measurementId: 'G-YBR4M0PC41',
  },
};

export default function getFirebaseConfig(firebaseProjectId) {
  const projectId =
    !firebaseProjectId || !firebaseConfig[firebaseProjectId]
      ? DEFAULT_PROJECT_ID
      : firebaseProjectId;

  // eslint-disable-next-line no-console
  console.debug(`[Firebase] Using Project ID "${projectId}".`);

  return firebaseConfig[projectId];
}
