import React from 'react';
import { Field } from 'formik';
import { CheckboxGroup } from 'components';

const QuestionDemographicsInsecureFinancial: React.FC = () => (
  <Field
    label={<>In the past 12 months, did you experience any of the following:</>}
    component={CheckboxGroup}
    noneOfTheAbove
    noneOfTheAboveName="fin_secure"
    fieldsets={[
      {
        options: [
          {
            name: 'fin_insecure_1',
            label: (
              <>
                Not pay or underpay the full tuition and fees bill from your
                school because of financial problems?
              </>
            ),
          },
          {
            name: 'fin_insecure_2',
            label: (
              <>
                Not pay or under pay your rent or mortgage or housing bill
                because of financial problems?
              </>
            ),
          },
          {
            name: 'fin_insecure_3',
            label: (
              <>
                Move in with other people, even for a little while, because of
                financial problems?
              </>
            ),
            value: true,
          },
          {
            name: 'fin_insecure_4',
            label: <>Been homeless?</>,
          },
        ],
      },
    ]}
  />
);

export default QuestionDemographicsInsecureFinancial;
