import firebase from 'firebase/compat/app';
import { firestore, ANSWERS_COLLECTION, RESPONSES_COLLECTION } from './index';
import { AnswerDoc, ResponseDoc } from 'index.d';
import isDevelopment from 'utils/isDevelopment';

import { getUserLocaleFromSupported } from 'i18n';
import { transformResponse } from './transformResponse';

async function addResponse({ surveyLabel = '', meta = {} }) {
  const locale = await getUserLocaleFromSupported(surveyLabel);

  const batch = firestore.batch();

  // Create a `responses` and `answers` document with matching document ID.
  // - `responses` will be the client readable version with boolean `answers`.
  // - `answers` will be the full version that contains `answers`.
  const responsesRef = firestore.collection(RESPONSES_COLLECTION).doc();
  const answersRef = firestore
    .collection(ANSWERS_COLLECTION)
    .doc(responsesRef.id);

  // https://firebase.google.com/docs/firestore/manage-data/add-data#server_timestamp
  const createdOn = firebase.firestore.FieldValue.serverTimestamp();

  const newResponse = transformResponse({
    createdOn,
    modifiedOn: createdOn,
    surveyLabel,
    // Optional query string parameters that can be set for new responses.
    meta,
    // Between 0 and 100. Survey pages will be able to set progress. This
    // won't always be an precise indicator of % because of branching.
    progress: 0,
    // Page label to allow continuing surveys.
    page: null,
    // Track which RandomOne questions have been seen.
    questionsSeen: {},
    // The user's key/value responses.
    // Important! See https://github.com/PERTS/saturn/issues/65 We are
    // initializing this to `null` and not an empty object so that we can
    // disallow empty objects on document updates. Huh? Firestore rules check
    // the state of the entire document as it would be after the write. If we
    // initialize to an empty object, then some future updates might be
    // prevented from writing since the object will be empty.
    answers: null,
    // Answer keys found here will be treated as sensitive data.
    // Example: { fidelity_why_not: true }
    isSensitive: {},
    // Show the text-to-speech UI?
    textToSpeech: false,
    // The user's locale.
    locale,
  });

  batch.set(answersRef, newResponse as AnswerDoc);
  batch.set(responsesRef, newResponse as ResponseDoc);

  return batch.commit().then(() => {
    isDevelopment() &&
      // eslint-disable-next-line no-console
      console.debug(
        'Successfully added new response.',
        responsesRef.id,
        newResponse,
      );

    return responsesRef.id;
  });
}

export default addResponse;
