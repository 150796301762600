// The Block component allows you to group Page components that should all
// receive a common set of props. Most commonly, this will allow us to provide
// the same `showWhen` / `hideWhen` prop for filtering pages based on portal
// provided parameters like `saw_demographics` or `learning_conditions`.
//
// Example usage:
//
// <Pages>
//   <Block hideWhen={sawDemographics}>
//     <Page label="demographics1" next="demographics2" />
//     <Page label="demographics2" next="conclusion" />
//   </Block>
// </Pages>
//
// The previous example usage would be similar to:
//
// <Pages>
//   <Block
//     hideWhen={sawDemographics}
//     blockLabelPrefix="demographics"
//     blockNextLabel="conclusion"
//   >
//     <Page />
//     <Page />
//   </Block>
// </Pages>

import React, { cloneElement } from 'react';
import { useParams } from 'react-router-dom';
import every from 'lodash/every';
import some from 'lodash/some';
import { ResponseDocContextValue } from '../../index.d';
import { Page } from 'components';
import { PathParams } from 'index.d';

// getChildAndNextLabel determines the Page `label` and Page `next` props that
// will be used when rendering out Page components within the Block. Page
// components can be manually provided these props, but we can also props at the
// Block level so that these will be automatically generated.
const getChildAndNextLabel = (
  // Current Page component.
  child: any,
  // Current Page index, as provided by `React.Children.map`.
  childIndex: number,
  // The length of children, all Page components within this Block.
  childenLength: number,
  // See component Props type.
  blockLabelPrefix?: String,
  // See component Props type.
  blockNextLabel?: String,
  // See component Props type.
  blockNextPrefix?: String,
) => {
  let childPageLabel;
  let childNextLabel;

  if (blockLabelPrefix && (blockNextPrefix || blockNextLabel)) {
    // React.Children.map provides an index that starts at 0. We want page
    // labels to start at 1, so just use the index and add 1.
    childPageLabel = `${blockLabelPrefix}${childIndex + 1}`;

    // The last page within this Block will link to the start of the next Block.
    const isLastPage = childIndex + 1 === childenLength;

    childNextLabel = isLastPage
      ? // If the last page, then link to the first page within the next Block.
        blockNextLabel || `${blockNextPrefix}1`
      : // If not the last page, then `next` will be the next page within the
        // current Block.
        `${blockLabelPrefix}${childIndex + 2}`;
  } else {
    // If blockLabelPrefix and blockNextPrefix/blockNextLabel are not provided,
    // then default back to the Page's `props.label` and `props.next`.
    childPageLabel = child.props.label;
    childNextLabel = child.props.next;
  }

  return {
    childPageLabel,
    childNextLabel,
  };
};

type Props = {
  children: any;
  // The prefix of the `label` prop to be provided. For example, if you provided
  // `blockLabelPrefix="ffg"`, then Page(s) within this Block would be labeled
  // `ffg1`, `ffg2`, `ffg3`, an so on.
  blockLabelPrefix?: String;
  // The `next` prop that will be provided to the last Page component within
  // this Block.
  blockNextLabel?: String;
  // The prefix of the `next` prop to be provided to the last Page component
  // within this Block. A `1` will be appended to this label since the first
  // page of each set of Block Page(s) ends up being `${blockLabelPrefix}1`.
  blockNextPrefix?: String;
  // Allow Block to provide any props to children Page components.
  [key: string]: any;
};

const Block: React.FC<Props> = ({
  children,
  blockLabelPrefix,
  blockNextLabel,
  blockNextPrefix,
  ...blockProvidedProps
}) => {
  // pageLabel from route params.
  const { pageLabel } = useParams<PathParams>();

  return (
    <>
      {React.Children.map(children, (child, childIndex) => {
        const { childPageLabel, childNextLabel } = getChildAndNextLabel(
          child,
          childIndex,
          children.length,
          blockLabelPrefix,
          blockNextLabel,
          blockNextPrefix,
        );

        // The simplest way for Block to pass-through props down to its child
        // Page components is for Pages to render all Block components (along
        // with their Page children).
        //
        // Because we are doing this, the route matching that is occurring for
        // Page components in Pages is not happening. So, we're duplicating the
        // display logic from components/Pages here.

        // Only display `Page` components where the `label` matches the
        // `:pageLabel` of the route.
        const shouldDisplayPage =
          child.type === Page && child.props && childPageLabel === pageLabel;

        const childNavigationLabels = {
          label: childPageLabel,
          next: childNextLabel,
        };

        // Merge Block showWhen with Page showWhen. Both must be true.
        const combinedShowWhenFn = (response: ResponseDocContextValue) =>
          every(
            // Place both showWhen functions into an array,
            [blockProvidedProps.showWhen, child.props.showWhen]
              // then filter out any undefined functions,
              .filter((fn) => fn !== undefined),
            // and ensure `every` function returns true on the response.
            (showWhenFunction) => showWhenFunction(response),
          );

        const combinedShowWhenProp =
          blockProvidedProps.showWhen || child.props.showWhen
            ? {
                showWhen: combinedShowWhenFn,
              }
            : {};

        // Merge Block hideWhen with Page hideWhen. Hide if any is true.
        const combinedHideWhenFn = (response: ResponseDocContextValue) =>
          some(
            // Place both hideWhen functions into an array,
            [blockProvidedProps.hideWhen, child.props.hideWhen]
              // then filter out any undefined functions,
              .filter((fn) => fn !== undefined),
            // and ensure `some` function returns true on the response.
            (hideWhenFunction) => hideWhenFunction(response),
          );

        const combinedHideWhenProp =
          blockProvidedProps.hideWhen || child.props.hideWhen
            ? {
                hideWhen: combinedHideWhenFn,
              }
            : {};

        return (
          shouldDisplayPage &&
          cloneElement(child, {
            ...blockProvidedProps,
            ...combinedShowWhenProp,
            ...combinedHideWhenProp,
            ...childNavigationLabels,
          })
        );
      })}
    </>
  );
};

export default Block;
