import styled from 'styled-components';

const PageNavigationStyled = styled.div`
  padding: 10px 0;
  text-align: center;

  button {
    font-size: 14pt;
  }
`;

export default PageNavigationStyled;
