import { LocaleSwitch, Question, TextToSpeechContent } from 'components';
import { locales } from 'i18n';

const messages = {
  [locales.ENGLISH]: () => (
    <>
      <Question.Title>
        For the next three questions, think about your math class.
      </Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            For the next three questions, think about your <u>math</u> class.
            Math includes subjects like arithmetic, pre-algebra, algebra, math
            concepts, geometry, trigonometry, calculus, statistics, and
            quantitative reasoning.
          </p>
          <p>
            If you take more than one math class, please think about <u>all</u>{' '}
            of your math classes.
          </p>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),

  [locales.SPANISH]: () => (
    <>
      <Question.Title>
        Para las próximas tres preguntas, piensa en tu clase de matemáticas.
      </Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            Para las próximas tres preguntas, piensa en tu clase de{' '}
            <u>matemáticas</u>. Matemáticas incluye materias como aritmética,
            preálgebra, álgebra, matemáticas conceptos, geometría,
            trigonometría, cálculo, estadística y razonamiento cuantitativo.
          </p>
          <p>
            Si toma más de una clase de matemáticas, piense en <u>todas</u> tus
            clases de matemáticas.
          </p>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),
};

const MathIntroduction = () => (
  <LocaleSwitch label="sesStudent23MathIntro" messages={messages} />
);

export default MathIntroduction;
