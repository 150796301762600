import React from 'react';
import { Field } from 'formik';
import { RadioGroup, TextFieldOther } from 'components';

const QuestionFidelityHonest: React.FC = () => (
  <Field
    name="fidelity_honest"
    label={
      <>
        Do you feel comfortable answering these questions honestly? (If no, why
        not?)
      </>
    }
    component={RadioGroup}
    options={[
      { label: <>Yes</>, value: 1 },
      { label: <>No</>, value: 0 },
    ]}
    otherField={
      <Field
        name="fidelity_why_not"
        component={TextFieldOther}
        label="Why not?"
        margin="normal"
        variant="outlined"
        linkedFieldName="fidelity_honest"
        linkedFieldTest={0}
      />
    }
  />
);

export default QuestionFidelityHonest;
