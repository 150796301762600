// NOTE
// - learning condition separator is `-` (hyphen)
// - question label separator is `_` (underscore)

export const surveyLabel = 'sesTeacher23';

export const learningConditions = [
  'faculty-mindset-beliefs',
  'trusting-community',
  'learning-culture',
  'perspective-taking',
  'relationship-efficacy',
  'authoritarian-discipline-beliefs',
  'teacher-student-voice',
  'teacher-burnout',
  'attitudes-towards-students',
];
