import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import { RadioGroup } from 'components';

const QuestionGender: React.FC = () => (
  <Field
    name="grade"
    label={<FormattedMessage id="q.grade.label" />}
    component={RadioGroup}
    options={[
      {
        label: <FormattedMessage id="q.grade.answer.grade3" />,
        value: '3',
      },
      {
        label: <FormattedMessage id="q.grade.answer.grade4" />,
        value: '4',
      },
      {
        label: <FormattedMessage id="q.grade.answer.grade5" />,
        value: '5',
      },
      {
        label: <FormattedMessage id="q.grade.answer.grade6" />,
        value: '6',
      },
      {
        label: <FormattedMessage id="q.grade.answer.grade7" />,
        value: '7',
      },
      {
        label: <FormattedMessage id="q.grade.answer.grade8" />,
        value: '8',
      },
      {
        label: <FormattedMessage id="q.grade.answer.grade9" />,
        value: '9',
      },
      {
        label: <FormattedMessage id="q.grade.answer.grade10" />,
        value: '10',
      },
      {
        label: <FormattedMessage id="q.grade.answer.grade11" />,
        value: '11',
      },
      {
        label: <FormattedMessage id="q.grade.answer.grade12" />,
        value: '12',
      },
      {
        label: <FormattedMessage id="q.grade.answer.other" />,
        value: 'other',
      },
      {
        label: <FormattedMessage id="q.grade.answer.opt_out" />,
        value: 'opt_out',
      },
    ]}
  />
);

export default QuestionGender;
