import get from 'lodash/get';

import { useResponseContext } from 'services/responseContext';

const useMeta = (
  fieldName: string,
  defaultValue?: string,
): string | undefined =>
  get(useResponseContext(), `meta.${fieldName}`, defaultValue);

export default useMeta;
