import { SurveyBlock } from 'types';
import QuestionRace from './QuestionRace';
import QuestionRaceFollowup from './QuestionRaceFollowup';
import QuestionGender from './QuestionGender';
import sawDemographics from 'utils/sawDemographics';
import whenGenderQuestionDisabled from 'utils/whenGenderQuestionDisabled';

export const config = {
  label: 'demographics-race-gender',
  name: 'Demographics Race/Gender',
};

const block: SurveyBlock = {
  component: 'Block',
  label: 'demographics',
  props: {
    hideWhen: sawDemographics,
    waitBeforeNext: true,
  },
  pages: [
    {
      component: 'Page',
      questions: [
        {
          component: QuestionRace,
          label: 'race',
          props: {},
        },
        {
          component: QuestionRaceFollowup,
          label: 'racefollowup',
          props: {},
        },
      ],
    },
    {
      component: 'Page',
      props: {
        hideWhen: [sawDemographics, whenGenderQuestionDisabled],
      },
      questions: [
        {
          component: QuestionGender,
          label: 'gender',
          props: {},
        },
      ],
    },
  ],
};

export default block;
