import { Row, Col } from 'perts-ui';

import {
  ImageStar,
  LocaleSwitch,
  Question,
  TextToSpeechContent,
} from 'components';
import { locales } from 'i18n';

const messages = {
  [locales.ENGLISH]: () => (
    <>
      <Question.Title>Thank you!</Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <Row>
            <Col hAlign="center">
              <p>You&rsquo;re all done.</p>
            </Col>
          </Row>
          <Row>
            <Col hAlign="center">
              <ImageStar />
            </Col>
          </Row>
          <Row>
            <Col hAlign="center">
              <p>Thanks for providing feedback.</p>
            </Col>
          </Row>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),
};

const SurveyComplete = () => (
  <LocaleSwitch label="SurveyComplete" messages={messages} />
);

export default SurveyComplete;
