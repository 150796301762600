import { useEffect } from 'react';
import { updateResponse } from 'services';

export const useUpdateResponseProgressValue = (
  responseId: string | undefined,
  progress: number | undefined,
) =>
  useEffect(
    function updateResponseProgressValue() {
      // The Page `progress` prop is optional. Note: We're also checking typeof
      // because react-router provides this type as `string | undefined`.
      if (progress && typeof responseId === 'string') {
        updateResponse(responseId, { progress })
          .then((success) => {
            // eslint-disable-next-line no-console
            console.debug(`Progress update successful. ${success}`);
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.debug('Progress update error.', err);
          });
      }
    },
    [progress, responseId],
  );
