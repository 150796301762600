import blockFromMetricsConfig from 'utils/blockFromMetricsConfig';

const { config, block } = blockFromMetricsConfig(
  'elevate23',
  'feedback-for-growth',
  'Feedback for Growth',
  'ffg',
  {
    contextReminderProp: 'class_name',
    waitBeforeNext: true,
  },
);

export { config, block as default };
