import { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { RadioGroup } from 'components';
import { FormattedMessage } from 'react-intl';
import { getRaceMultiOption, getRacePreferenceOptions } from '@perts/config';

type Props = {
  /**
   * `true` if the Question will be displayed. The Question will still be
   * rendered so that Formik will retain access to the fields/answers. This is
   * useful to allow for follow up questions.
   */
  display?: boolean;
  /**
   * Function to route the app to the next page/question.
   */
  routeNext?: Function;
};
const QuestionRaceFollowup = ({ display, routeNext }: Props) => {
  const { values, setFieldValue } = useFormikContext<{
    [label: string]: any;
  }>();

  // Populate the available options from those selected on the initial question.
  const options = getRacePreferenceOptions()
    .filter(({ name }) => values[name])
    .map(({ messageLabel, name }) => ({
      value: name,
      label: <FormattedMessage id={`q.race.label.${messageLabel}`} />,
    }));

  // Redirect to nextRoute if there are no choices.
  useEffect(() => {
    if (display && options.length <= 1 && routeNext) {
      routeNext();
    }
  }, [display, options, routeNext]);
  // Do not display the follow up if there are no choices.
  if (options.length <= 1) {
    return null;
  }

  // Always allow the user to select "no preference".
  const multiOption = getRaceMultiOption();
  options.push({
    value: multiOption.name,
    label: <FormattedMessage id={`q.race.label.${multiOption.messageLabel}`} />,
  });

  const onChange = (e: { target: { name: string; value: any } }) => {
    setFieldValue('race_preference', e.target.value);
  };

  // We don't want to pre-select the follow up question with the value we set
  // programmatically on the previous page. We can't reset the form to achieve
  // the blank form state because we need those values in Formik to properly
  // render the options. So use a temporary field name (which won't get the
  // value from the previous page) and then sync it to race_preference.
  // Note: This will result in the _NOT_PRESELECTED value being stored with the
  // user's response.

  return (
    <div style={{ display: display ? 'block' : 'none' }}>
      <Field
        name="race_preference_NOT_PRESELECTED"
        label={<FormattedMessage id="q.race.label.followup" />}
        component={RadioGroup}
        options={options}
        onChange={onChange}
      />
    </div>
  );
};

export default QuestionRaceFollowup;
