import styled, { css } from 'styled-components/macro';
import theme from '../theme';
import Flex from '../Flex';
import FlexItem from '../FlexItem';

type Props = {
  alignItems: string;
  justifyContent: string;
  spacing: number;
  children: React.ReactNode;
};

// https://github.com/microsoft/TypeScript/issues/37597
const FlexRow = styled(Flex as any)<Props>`
  flex-direction: row;

  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `};

  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};

  ${FlexItem}:not(:first-child) {
    margin-left: ${(props) =>
      props.spacing !== undefined
        ? `${props.spacing}px`
        : theme.units.fieldPadding};
  }

  ${FlexItem}:empty {
    margin-left: 0;
  }
`;

export default FlexRow;
