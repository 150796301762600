import { SurveyBlock } from 'types';
import QuestionRace from './QuestionRace';
import QuestionRaceFollowup from './QuestionRaceFollowup';
import QuestionGender from './QuestionGender';
import sawDemographics from 'utils/sawDemographics';

export const config = {
  label: 'demographics-race-gender',
  name: 'Demographics Race/Gender',
};

// FYI this version of the race and gender block has slightly different behavior
// compared to block-race-gender22: it shows users a message about how they will
// NOT necessarily have an opportunity to answer these demographics questions a
// second time. Current English language for this is similar to: "Please answer
// carefully. You will not be able to change your response..."
//
// This was added specifically for catalyze23.

const block: SurveyBlock = {
  component: 'Block',
  label: 'demographics',
  props: {
    hideWhen: sawDemographics,
    waitBeforeNext: true,
  },
  pages: [
    {
      component: 'Page',
      questions: [
        {
          component: QuestionRace,
          label: 'race',
          props: {
            contextReminderProp: 'class_name',
            contentReminderMessageKey: 'context.demographics',
          },
        },
        {
          component: QuestionRaceFollowup,
          label: 'racefollowup',
          props: {},
        },
      ],
    },
    {
      component: 'Page',
      questions: [
        {
          component: QuestionGender,
          label: 'gender',
          props: {
            contextReminderProp: 'class_name',
            contentReminderMessageKey: 'context.demographics',
          },
        },
      ],
    },
  ],
};

export default block;
