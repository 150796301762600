import blockFromMetricsConfig from 'utils/blockFromMetricsConfig';

const { config, block } = blockFromMetricsConfig(
  'elevate23',
  'classroom-community',
  'Classroom Community',
  'cc',
  {
    contextReminderProp: 'class_name',
    waitBeforeNext: true,
  },
);

export { config, block as default };
