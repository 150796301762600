// Uses Formik's setValues function to set a corresponding form value that
// indicates that the field contains sensitive data. This sensitive data
// will be filtered out of some of our data stores.

// See:
// - https://github.com/PERTS/saturn/issues/262
// - https://github.com/PERTS/saturn/pull/268

import { useEffect } from 'react';

const useMarkFieldSensitive = (props, fieldName) =>
  useEffect(() => {
    props.form.setValues({
      [`${fieldName}.isSensitive`]: true,
    });

    // Important. Despite what this ESLint rule wants to change the useEffect
    // dependencies array to, we do NOT want to rerun this useEffect when
    // `props.form` changes. `props.form` changes anytime a value in the form
    // changes, including the change caused by this useEffect, which then
    // causes this useEffect to rerun repeatedly.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName]);

export default useMarkFieldSensitive;
