import { LocaleSwitch, Question, TextToSpeechContent } from 'components';
import { locales } from 'i18n';

const messages = {
  [locales.ENGLISH]: () => (
    <>
      <Question.Title>
        For the next three questions, think about your English class.
      </Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            For the next three questions, think about your <u>English</u> class.
            English includes subjects like English, Literature, Writing,
            Composition, and Language Arts.
          </p>
          <p>
            If you take more than one English class, please think about{' '}
            <u>all</u> of your English classes.
          </p>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),

  [locales.SPANISH]: () => (
    <>
      <Question.Title>
        Para las próximas tres preguntas, piensa en tu clase de inglés.
      </Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            Para las próximas tres preguntas, piensa en tu clase de{' '}
            <u>inglés</u>. El inglés incluye materias como inglés, literatura,
            escritura, Composición y Artes del Lenguaje.
          </p>
          <p>
            Si toma más de una clase de inglés, piense en <u>todas</u> sus
            clases de inglés.
          </p>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),
};

const EnglishIntroduction = () => (
  <LocaleSwitch label="sesStudent23EnglishIntro" messages={messages} />
);

export default EnglishIntroduction;
