// LocaleSwitch uses `locale` (context) to determine which React component,
// provided via the `messages` prop, to display.
//
// Example usage:
//
// import { locales } from 'i18n';
//
// const messages = {
//   [locales.ENGLISH]: () => <div>The English translation.</div>,
//   [locales.SPANISH]: () => <div>The Spanish translation.</div>,
// };
//
// const Component = () => (
//   <LocaleSwitch label="label_for_reference" messages={messages} />
// );

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LocaleContext } from 'i18n/locales';

const LocaleSwitch = ({ label, messages }) => {
  const { locale } = useContext(LocaleContext);

  if (messages && messages[locale]) {
    const Render = messages[locale];
    return <Render />;
  }

  console.error(
    `${label} did not have a messages defined for locale "${locale}".`,
  );

  return null;
};

LocaleSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  messages: PropTypes.objectOf(PropTypes.elementType),
};

export default LocaleSwitch;
