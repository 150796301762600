import styled from 'styled-components';
import imageStarSrc from './iStock-840535330.png';

const presentationImagesAlt = '';

const ImageStarStyled = styled.img`
  /* Force a width that works for this particular image/scenario. */
  width: 300px;

  /* Limit width on narrower screens. */
  max-width: 100%;
  height: 100%;
`;

const ImageStar = () => (
  <ImageStarStyled src={imageStarSrc} alt={presentationImagesAlt} />
);

export default ImageStar;
