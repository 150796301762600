import { SurveyConfig } from 'types';
import { locales } from 'i18n';

import blockFromMetricsConfig from '../../utils/blockFromMetricsConfig';

import blockAffirmingIdentities from 'surveys/common/block-affirming-identities22';
import blockClassroomCommunity from 'surveys/common/block-classroom-community';
import blockFeedbackForGrowth from 'surveys/common/block-feedback-for-growth22';
import blockLearningGoals from 'surveys/common/block-learning-goals';
import blockMeaningfulWork from 'surveys/common/block-meaningful-work';
import blockStudentVoice from 'surveys/common/block-student-voice';
import blockSupportiveTeaching22 from 'surveys/common/block-supportive-teaching22';
import blockTeacherCaring from 'surveys/common/block-teacher-caring';
import blockWellOrganizedClass22 from 'surveys/common/block-well-organized-class22';

import blockDemographicsRaceGenderGrade from 'surveys/common/block-race-gender-grade24';
import blockFidelity from 'surveys/common/block-fidelity';

import SurveyIntroduction from './SurveyIntroduction';
import SurveyComplete from './SurveyComplete';

export const supportedLocales = [locales.ENGLISH, locales.SPANISH];

export const elevate24: SurveyConfig = {
  name: 'Elevate',
  label: 'elevate24',
  survey: [
    {
      component: 'Page',
      label: 'introduction',
      progress: 1,
      props: {
        waitBeforeNext: true,
      },
      questions: [
        {
          label: 'intro_your_opinion',
          component: SurveyIntroduction,
          props: {},
        },
      ],
    },

    // MetricSet: Standard
    blockAffirmingIdentities,
    blockClassroomCommunity,
    blockFeedbackForGrowth,
    blockLearningGoals,
    blockMeaningfulWork,
    blockStudentVoice,
    blockSupportiveTeaching22,
    blockTeacherCaring,
    blockWellOrganizedClass22,

    // Metrics: Elementary Measures 24
    blockFromMetricsConfig(
      'elevate24',
      'affirming-identities-elem',
      'Affirming Identities',
      'elem-ai',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'elevate24',
      'classroom-community-elem',
      'Classroom Community',
      'elem-cc',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'elevate24',
      'feedback-for-growth-elem',
      'Feedback for Growth',
      'elem-ffg',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'elevate24',
      'meaningful-work-elem',
      'Meaningful Work',
      'elem-mw',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'elevate24',
      'student-voice-elem',
      'Student Voice',
      'elem-sv',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'elevate24',
      'supportive-teaching-elem',
      'Supportive Teaching',
      'elem-st',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'elevate24',
      'teacher-caring-elem',
      'Teacher Caring',
      'elem-tc',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'elevate24',
      'well-organized-class-elem',
      'Well Organized Class',
      'elem-woc',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,

    // MetricSet: Math Pilot 22
    blockFromMetricsConfig(
      'elevate24',
      'autonomy-over-process',
      'Autonomy Over Process',
      'math-aop',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'elevate24',
      'collaborative-learning',
      'Collaborative Learning',
      'math-cl',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'elevate24',
      'learning-from-misconceptions',
      'Learning from Misconceptions',
      'math-lfm',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'elevate24',
      'math-identity',
      'Math Identity',
      'math-mi',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'elevate24',
      'relevance-of-math',
      'Relevance of Math',
      'math-rom',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,

    blockFidelity,
    blockDemographicsRaceGenderGrade,
    {
      component: 'Page',
      label: 'thankyou',
      progress: 100,
      updateParticipantData: {
        pdKey: 'saw_demographics',
        pdValue: 'true',
      },
      questions: [
        {
          label: 'surveycomplete',
          component: SurveyComplete,
          props: {},
        },
      ],
    },
  ],
};
