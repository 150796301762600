// extractTextFromJsx will return a string of all readable text in JSX node and
// the node's children.

import { Children } from 'react';

const extractTextFromJsx = (node) => {
  // If node is a string, we'll read it.
  if (typeof node === 'string') {
    return node;
  }

  // If node is an array, Recurse down into array to search for strings.
  if (Array.isArray(node)) {
    return Children.map(node, (child) => `${extractTextFromJsx(child)} `);
  }

  // If node has `props.children`, it's a React component and we don't want to
  // read it out loud. Recurse down into the node to search for strings.
  if (node.props && node.props.children) {
    return Children.map(
      node.props.children,
      (child) => `${extractTextFromJsx(child)} `,
    );
  }

  // Somehing else? Ignore it.
  return '';
};

export default extractTextFromJsx;
