// Returns an array of the supported locales of they survey with `surveyLabel`.
// If a survey has not exported `supportLocales`, this will be undefined.

async function getSupportedLocales(surveyLabel) {
  try {
    const { supportedLocales } = await import(`surveys/${surveyLabel}`);
    return supportedLocales;
  } catch {
    return undefined;
  }
}

export default getSupportedLocales;
