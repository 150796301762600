import { useIntl, FormattedMessage } from 'react-intl';
import { Field, useFormikContext } from 'formik';
import { CheckboxGroup, TextFieldOther } from 'components';
import { raceMultiValue } from 'surveys/config';
import {
  getRaceInitialChoiceCategories,
  getRacePreferenceOptions,
} from '@perts/config';

type Props = {
  /**
   * `true` if the Question will be displayed. The Question will still be
   * rendered so that Formik will retain access to the fields/answers. This is
   * useful to allow for follow up questions.
   */
  display?: boolean;
};

const QuestionRace = ({ display }: Props) => {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext<any>();

  // Set the follow-up question value based on what the user has chosen on this
  // initial selection question. This ensures a sensible default value for the
  // follow-up question even if the user does not answer it.
  const onChange = (e: { target: { name: string; value: any } }) => {
    const updatedValues = {
      ...values,
      [e.target.name]: e.target.value,
    };

    const selected = getRacePreferenceOptions()
      .filter((option) => updatedValues[option.name])
      .map((option) => option.name);

    const preference =
      selected.length > 1
        ? raceMultiValue
        : selected.length === 1
        ? selected[0]
        : '';

    setFieldValue('race_preference', preference);
  };

  const fieldsets = getRaceInitialChoiceCategories().map((ctg) => ({
    legend: <FormattedMessage id={`q.race.legend.${ctg.messageLegend}`} />,
    options: ctg.options.map((opt) => ({
      name: opt.name,
      label: <FormattedMessage id={`q.race.label.${opt.messageLabel}`} />,
    })),
    otherField:
      ctg.messageLegend === 'other' ? (
        <Field
          name="race_text"
          component={TextFieldOther}
          label={<FormattedMessage id="q.race.label.race_text" />}
          placeholder={intl.formatMessage({
            id: 'q.race.placeholder.race_text',
          })}
          linkedFieldName={ctg.options[0]?.name || 'race_other'}
        />
      ) : undefined,
  }));

  return (
    <div style={{ display: display ? 'block' : 'none' }}>
      <Field
        label={<FormattedMessage id="q.race.label" />}
        component={CheckboxGroup}
        onChange={onChange}
        fieldsets={fieldsets}
      />
    </div>
  );
};

export default QuestionRace;
