import { UnorderedList, ListItem } from 'perts-ui';
import { LocaleSwitch, Question, TextToSpeechContent } from 'components';
import { FaCheck } from 'react-icons/fa';
import { locales } from 'i18n';

const messages = {
  [locales.ENGLISH]: () => (
    <>
      <Question.Title>Your opinion matters!</Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            Your teacher wants to know how to make this class a better place for
            you to learn. This 5-minute survey is a chance for you to provide
            honest, private feedback.
          </p>

          <UnorderedList>
            <ListItem icon={<FaCheck />}>
              This survey is only about the class taught by the teacher who
              invited you to take the survey.
            </ListItem>
            <ListItem icon={<FaCheck />}>
              To protect your privacy, we will not share your name with your
              teacher in connection with your answers. Teachers get reports that
              group students&rsquo; answers together. You can{' '}
              <a href="https://perts.net/elevate/class-report">
                view this sample report
              </a>{' '}
              as an example.
            </ListItem>
            <ListItem icon={<FaCheck />}>
              Please be honest. There are no right or wrong answers.
            </ListItem>
            <ListItem icon={<FaCheck />}>
              If you don&rsquo;t understand a question, ask your teacher for
              help.
            </ListItem>
          </UnorderedList>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),

  [locales.SPANISH]: () => (
    <>
      <Question.Title>¡Tu opinión importa!</Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            Tu maestro quiere saber cómo hacer esta clase un lugar mejor para
            aprender. Esta encuesta de 5 minutos es una oportunidad para
            compartir con tu maestro comentarios honestos y privados.
          </p>

          <UnorderedList>
            <ListItem icon={<FaCheck />}>
              Esta encuesta es solo sobre la clase que enseña el maestro quien
              te invitó a completarla.
            </ListItem>
            <ListItem icon={<FaCheck />}>
              Para proteger tu privacidad, no compartiremos tu nombre con tu
              maestro en relación a tus respuestas. Maestros reciben reportes
              que agrupan las respuestas de todos los estudiantes. Puedes{' '}
              <a href="https://perts.net/copilot/report">ver este reporte</a>{' '}
              como un ejemplo.
            </ListItem>
            <ListItem icon={<FaCheck />}>
              Por favor, se honesto. No hay respuestas correctas o incorrectas.
            </ListItem>
            <ListItem icon={<FaCheck />}>
              Si no entiendes una pregunta, pídele ayuda a tu maestro.
            </ListItem>
          </UnorderedList>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),
};

const SurveyIntroduction = () => (
  <LocaleSwitch label="Elevate21QuestionIntro" messages={messages} />
);

export default SurveyIntroduction;
