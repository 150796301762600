import blockFromMetricsConfig from 'utils/blockFromMetricsConfig';

const { config, block } = blockFromMetricsConfig(
  'elevate23',
  'supportive-teaching',
  'Supportive Teaching',
  'st',
  {
    contextReminderProp: 'class_name',
    waitBeforeNext: true,
  },
);

export { config, block as default };
