// LikertScale (Formik Field component) displays a Likert Scale input field.
// https://en.wikipedia.org/wiki/Likert_scale
//
// Example usage:
//
// <Field
//   name="like_icecream"
//   label="I like ice cream."
//   component={LikertScale}
//   likertN={6}
// />
//
// Example usage, reverse likert option values with `likertReverse`:
//
// <Field
//   name="like_icecream"
//   label="I dislike ice cream."
//   component={LikertScale}
//   likertN={6}
//   likertReverse
// />

import React, { useContext } from 'react';
import { FieldProps } from 'formik';
import { FlexItem, FlexRow, RadioWithLabel } from 'perts-ui';
import { Question, TextToSpeechButton } from 'components';
import { LocaleContext } from 'i18n';
import { LikertScaleOptions, likertScaleOptions } from './likertScaleOptions';

type Props = {
  label: string;
  likertN: number;
  likertReverse?: boolean;
  options?: LikertScaleOptions;
} & FieldProps;

const LikertScale: React.FC<Props> = (props) => {
  const { locale } = useContext(LocaleContext);

  const { label, likertN, likertReverse = false, options } = props;
  const { onChange, name, value } = props.field;
  const { /* errors,*/ isSubmitting } = props.form;

  let likertOptions;

  if (options) {
    // Provided `options` will override default LikertScale options that are set
    // with `likertN` and `likertReverse`. Those props will not affect the
    // number of options displayed or the ordering.
    likertOptions = options[locale];

    if (likertN || likertReverse) {
      // eslint-disable-next-line no-console
      console.warn(
        'LikertScale `options` will override both `likertN` and `likertReverse` props.',
      );
    }
  } else {
    if (!likertScaleOptions[likertN]) {
      throw Error(`That Likert Scale option (likertN) does not exist.`);
    }

    // If the `likertReverse` prop is set, then reverse the `values` of the Likert
    // Scale options. For example: if `likertN={2}` and `likertReverse` then
    // `Disagree` would receive a value of `2` and `Agree` would receive a value
    // of `1`.
    likertOptions = likertReverse
      ? likertScaleOptions[likertN].map((o) => ({
          label: o.label,
          // This assumes that the default option values start at 1 and increment
          // by 1 and in ascending order. Example: 1, 2, 3, 4, 5, 6.
          value: likertScaleOptions[likertN].length - o.value + 1,
        }))
      : likertScaleOptions[likertN];
  }

  // Note: We don't currently have any validation or required fields in our
  // surveys, so we're spending the time to reimplement this with our new UI
  // until it's needed. Leaving this here as a note on how to access errors.
  // const error = errors[name];

  const isChecked = (v: number) => value === v;

  // Transform name/value pairing to what Formik's onChange expects.
  const handleChange = (n: string, v: any) => {
    const event = {
      target: {
        name: n,
        value: v,
      },
    };
    onChange(event);
  };

  return (
    <>
      <Question.Title>{label}</Question.Title>
      <Question.Content>
        {likertOptions.map((option) => (
          <FlexRow key={option.value}>
            <FlexItem>
              <RadioWithLabel
                value={option.value}
                label={option.label}
                name={option.label}
                disabled={isSubmitting}
                checked={isChecked(option.value)}
                onChange={() => handleChange(name, option.value)}
              />
            </FlexItem>

            <FlexItem grow={0}>
              <TextToSpeechButton text={option.label} />
            </FlexItem>
          </FlexRow>
        ))}
      </Question.Content>
    </>
  );
};

export default LikertScale;
