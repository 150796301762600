import 'perts-ui/styles/reset.css';
import 'perts-ui/styles/alignVertically.css';
import 'perts-ui/styles/bodyPadding.css';
import 'perts-ui/styles/boxSizingReset.css';
import './styles/app.css';
import './styles/colors.css';
import './styles/fonts.css';
import './styles/materialUiFixes.css';

import ReactDOM from 'react-dom';
import { IntlLocaleProvider, IntlMessagesProvider } from './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import isDevelopment from 'utils/isDevelopment';

if (!isDevelopment()) {
  Sentry.init({
    dsn: 'https://b7d1c85077d74ef3818c2c21989c214d@sentry.io/1855637',
  });
}

ReactDOM.render(
  <IntlLocaleProvider>
    <IntlMessagesProvider>
      <App />
    </IntlMessagesProvider>
  </IntlLocaleProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
