import { getProgramMetricConfig } from '@perts/config';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'perts-ui';

const SurveyGenerateUrl = () => {
  const { surveyLabel } = useParams<{ surveyLabel: string }>();
  const metricConfig = getProgramMetricConfig(surveyLabel);

  const metricsLabels = Object.values(metricConfig.metricIndex).map(
    (m) => m.label,
  );

  const [selectedMetrics, setSelectedMetrics] = useState(
    metricsLabels.map((metricLabel) => ({ metricLabel, active: true })),
  );

  const isMetricSelected = (metricLabel: string) => {
    const metric = selectedMetrics.find((m) => m.metricLabel === metricLabel);
    return metric?.active;
  };

  const toggleMetric = (metricLabel: string) => {
    const updatedMetrics = selectedMetrics.map((m) => {
      return {
        metricLabel: m.metricLabel,
        active: m.metricLabel === metricLabel ? !m.active : m.active,
      };
    });

    setSelectedMetrics(updatedMetrics);
  };

  const selectAll = () => {
    const updatedMetrics = metricsLabels.map((metricLabel) => ({
      metricLabel,
      active: true,
    }));
    setSelectedMetrics(updatedMetrics);
  };

  const selectNone = () => {
    const updatedMetrics = metricsLabels.map((metricLabel) => ({
      metricLabel,
      active: false,
    }));
    setSelectedMetrics(updatedMetrics);
  };

  const surveyUrlMetrics = selectedMetrics
    .map((m) => (m.active ? `"${m.metricLabel}"` : false))
    .filter(Boolean)
    .join(',');

  const surveyUrl = `/surveys/${surveyLabel}?testing=true&participant_id=Participant_TEST&survey_id=Survey_TEST&code=TEST&class_name="Demo Class"&learning_conditions=[${surveyUrlMetrics}]&open_response_lcs=[${surveyUrlMetrics}]`;

  return (
    <>
      <Row>
        <Col cols={6}>
          <div>
            <h2>Metrics</h2>

            {metricsLabels.map((metric) => (
              <div key={metric}>
                <label>
                  <input
                    type="checkbox"
                    checked={isMetricSelected(metric)}
                    onChange={() => toggleMetric(metric)}
                  />{' '}
                  <span>{metric}</span>
                </label>
              </div>
            ))}
          </div>

          <div onClick={() => selectAll()}>Select All</div>
          <div onClick={() => selectNone()}>Select None</div>
        </Col>
      </Row>

      <Row>
        <Col>
          <a href={surveyUrl}>{surveyUrl}</a>
        </Col>
      </Row>
    </>
  );
};

export default SurveyGenerateUrl;
