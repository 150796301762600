/* eslint-disable max-len */

// Common characters (https://www.w3.org/wiki/Common_HTML_entities_used_for_typography)
// Prefer – to -      [En dash]
// Prefer — to --     [Em (long) dash]
// Prefer ‘ to '      [Left single quote]
// Prefer ’ to '      [Right single quote, Apostrophe]
// Prefer “ to "      [Left double quote]
// Prefer ” to "      [Right double quote]
// Prefer … to ...    [Ellipsis]

import locales from '../locales';

export default {
  [locales.ENGLISH]: {
    'ui.button.nextPage': 'Next Page',
    'ui.button.takeYourTime': 'Please take time to read this carefully.',
    'ui.redirecting': 'Redirecting to {url}',
    'ui.survey.loading': 'Retrieving your survey.',
    'ui.survey.error.badResponseId':
      'We’re sorry. We could not find your survey.',
    'ui.survey.error.badSurveyCode': 'We’re sorry. That survey does not exist.',
    'ui.survey.error.generic':
      'We’re sorry. There was a problem preparing your survey. Please reload to try again.',
    'ui.survey.welcome': 'Welcome. Your survey is being setup.',
    'ui.textToSpeech.on': 'Text-to-Speech On',
    'ui.textToSpeech.off': 'Text-to-Speech Off',
    'ui.openResponse.label':
      'Please explain how you chose your answer. You can also add anything else about your answer that you would like to share.',
    'q.fidelityHonest.answer.yes': 'Yes',
    'q.fidelityHonest.answer.no': 'No',

    // Demographics: Gender
    'q.gender.label': 'What is your gender identity?',
    'q.gender.answer.female': 'Girl or Woman',
    'q.gender.answer.male': 'Boy or Man',
    'q.gender.answer.other': 'Non-Binary / Other',
    'q.gender.answer.opt_out': 'Prefer not to answer',

    // Demographics: Race
    'q.race.label':
      'With what group or groups do you identify? Please select any boxes that apply.',
    'q.race.label.followup':
      'Of your choices, which do you most identify with?',

    'q.race.legend.native_american': 'American Indian or Alaskan Native',
    'q.race.label.race_amindian': 'American Indian or Alaskan Native',

    'q.race.legend.asian_american': 'Asian / Asian American',
    'q.race.label.race_e_asian':
      'East Asian (e.g., Chinese, Japanese, Korean, Taiwanese)',
    'q.race.label.race_se_asian':
      'Southeast Asian (e.g., Burmese, Filipino, Hmong, Indonesian, Thai, Vietnamese)',
    'q.race.label.race_s_asian':
      'South Asian (e.g., Indian, Pakistani, Nepali, Sri Lankan)',
    'q.race.label.race_o_asian': 'Other Asian / Asian-American',

    'q.race.legend.hispanic_latino': 'Hispanic / Latino(a) / Latinx',
    'q.race.label.race_mex': 'Mexican-American / Chicano',
    'q.race.label.race_puerto_rican': 'Puerto Rican',
    'q.race.label.race_cuban': 'Cuban',
    'q.race.label.race_dominican': 'Dominican',
    'q.race.label.race_central_american': 'Central American',
    'q.race.label.race_south_american': 'South American',
    'q.race.label.race_o_latino': 'Other Hispanic / Latino(a) / Latinx',

    'q.race.legend.black': 'Black / African-American',
    'q.race.label.race_african_american': 'African American / Black',
    'q.race.label.race_african': 'African (Black / Sub-Saharan)',
    'q.race.label.race_caribbean': 'Caribbean',
    'q.race.label.race_o_black': 'Other Black',

    'q.race.legend.white': 'White / European-American',
    'q.race.label.race_european': 'European / European-American / White',
    'q.race.label.race_o_white': 'Other White',

    'q.race.legend.middle_eastern': 'Middle Eastern / North African',
    'q.race.label.race_middle_eastern': 'Middle Eastern',
    'q.race.label.race_n_african':
      'North African (e.g., Algerian, Egyptian, Libyan, Moroccan, Sudanese, Tunisian)',

    'q.race.legend.pacific_islander':
      'Native Hawaiian or Other Pacific Islander',
    'q.race.label.race_pac_isl': 'Native Hawaiian or Other Pacific Islander',

    'q.race.legend.other': 'Other Group',
    'q.race.label.race_other': 'Other Group (Please specify)',
    'q.race.label.race_other_followup': 'Other Group',
    'q.race.label.race_multi': 'I identify equally with more than one group.',
    'q.race.label.race_text': 'If other, please specify',
    'q.race.placeholder.race_text': 'Please specify',

    'q.race.legend.opt_out': 'Prefer not to answer',
    'q.race.label.opt_out': 'Prefer not to answer',

    // Demographics Grade
    'q.grade.label': 'What grade are you in?',
    'q.grade.answer.grade3': '3rd',
    'q.grade.answer.grade4': '4th',
    'q.grade.answer.grade5': '5th',
    'q.grade.answer.grade6': '6th',
    'q.grade.answer.grade7': '7th',
    'q.grade.answer.grade8': '8th',
    'q.grade.answer.grade9': '9th (Freshman)',
    'q.grade.answer.grade10': '10th (Sophmore)',
    'q.grade.answer.grade11': '11th (Junior)',
    'q.grade.answer.grade12': '12th (Senior)',
    'q.grade.answer.other': "My grade isn't shown here",
    'q.grade.answer.opt_out': 'Prefer not to answer',

    // Likert Scale
    'q.likert.answer.stronglyDisagree': 'Strongly Disagree',
    'q.likert.answer.disagree': 'Disagree',
    'q.likert.answer.slightlyDisagree': 'Slightly Disagree',
    'q.likert.answer.neither': 'Neither Agree Nor Disagree',
    'q.likert.answer.slightlyAgree': 'Slightly Agree',
    'q.likert.answer.agree': 'Agree',
    'q.likert.answer.stronglyAgree': 'Strongly Agree',

    // Contextual Messages
    'context.thisQuestionIsAbout': 'This question is about {class_name}.',
    'context.demographics':
      'Please answer carefully. You will not be able to change your response for {class_name}.',
  },
};
