import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import locales, { LocaleContext } from 'i18n/locales';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import { RadioGroup } from 'components';
import QuestionFidelityHonestWhyNot from './QuestionFidelityHonestWhyNot';

const label = 'fidelity_honest';

const messages = {
  [locales.ENGLISH]: (
    <>
      Do you feel comfortable answering these questions honestly? (If no, why
      not?)
    </>
  ),
  [locales.SPANISH]: (
    <>
      ¿Te sientes cómodo respondiendo estas preguntas honestamente? (Si no, ¿por
      qué no?)
    </>
  ),
};

const QuestionFidelityHonest = () => {
  const { surveyLabel } = useParams();
  const { locale } = useContext(LocaleContext);

  // When no message has been defined for the requested locale, fallback to
  // displaying the question label for development purposes.
  const questionLabel = (messages && messages[locale]) || label;

  if (questionLabel === label) {
    // If we haven't defined a message for the requested locale, it's probably a
    // mistake. Notify so we can get it added.
    console.error(
      `Question ${label} (${surveyLabel}) has no "${locale}" i18n message assigned.`,
    );
  }

  return (
    <Field
      name={label}
      label={questionLabel}
      component={RadioGroup}
      options={[
        {
          label: <FormattedMessage id="q.fidelityHonest.answer.yes" />,
          value: 1,
        },
        {
          label: <FormattedMessage id="q.fidelityHonest.answer.no" />,
          value: 0,
        },
      ]}
      otherField={<QuestionFidelityHonestWhyNot linkedFieldName={label} />}
    />
  );
};

export default QuestionFidelityHonest;
