import styled from 'styled-components';
import theme from 'components/theme';
import iconTogglePlay from './iconTogglePlay.png';
import iconToggleStop from './iconToggleStop.png';

const TextToSpeechButtonStyled = styled.button`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;

  background: ${theme.colors.primary};
  background-image: url(${(props) =>
    props.isSpeaking ? iconToggleStop : iconTogglePlay});
  background-repeat: no-repeat;
  background-size: contain, cover;
  border: 1px solid ${theme.colors.white};
  border-radius: ${theme.units.borderRadius};

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 5px ${theme.colors.primaryLight};
  }

  cursor: pointer;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.5);

  /* https://webaim.org/techniques/css/invisiblecontent/ */
  /* Visible to screen reader only */
  span {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
`;

export default TextToSpeechButtonStyled;
