import locales from 'i18n/locales';
import { SurveyPage } from 'types';

import { QuestionLikertScale } from 'components';

export const config = {
  label: 'fidelity',
  name: 'Fidelity',
  pageLabel: 'fidelity',
};

const block: SurveyPage = {
  component: 'Page',
  label: config.pageLabel,
  props: {
    waitBeforeNext: true,
    contextReminderProp: 'class_name',
  },
  questions: [
    {
      component: QuestionLikertScale,
      label: 'fidelity_class_better',
      props: {
        likertN: 5,
        messages: {
          [locales.ENGLISH]: (
            <>
              My instructor will try to use my answers to this survey to make
              class better for me.
            </>
          ),
          [locales.SPANISH]: (
            <>
              Mi maestro intentará usar mis respuestas de esta encuesta para
              mejorar la clase para mí.
            </>
          ),
        },
      },
    },
  ],
};

export default block;
