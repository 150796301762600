import blockFromMetricsConfig from 'utils/blockFromMetricsConfig';

const { config, block } = blockFromMetricsConfig(
  'ascend23',
  'social-connectedness',
  'Social Connectedness',
  'sc',
  {
    contextReminderProp: 'class_name',
    waitBeforeNext: true,
  },
);

export { config, block as default };
