// Helper `hideWhen` function to disable gender question:
// https://github.com/PERTS/perts/issues/2287

import { ResponseDocContextValue } from '../index.d';
import get from 'lodash/get';

const whenGenderQuestionDisabled = (
  response: ResponseDocContextValue,
): boolean => get(response, 'meta.gender_question_disabled') === 'true';

export default whenGenderQuestionDisabled;
