import { FormattedMessage } from 'react-intl';

const RedirectToPortal = () => {
  const redirectTo = 'https://perts.me';
  window.location.href = redirectTo;

  return (
    <div>
      <FormattedMessage
        id="ui.redirecting"
        values={{ url: <a href={redirectTo}>{redirectTo}</a> }}
      />
    </div>
  );
};

export default RedirectToPortal;
