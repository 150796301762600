import React, { useState } from 'react';
import styled from 'styled-components';

import theme from 'components/theme';
import { ImageStar, Question, Show, TextToSpeechContent } from 'components';

type Props = {
  codeSuffix: string;
};

const CenteredText = styled.div`
  text-align: center;
`;

const CompletionCode = styled.div`
  display: inline-block;

  margin: 16px auto;
  padding: 10px;

  border: 1px dashed #f50057;
  border-radius: 5px;

  font-size: 36px;
  white-space: nowrap;
`;

const FakeLink = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  font-size: ${theme.units.fontSizeBase};
`;

const QuestionCompletionCode: React.FC<Props> = ({ codeSuffix }) => {
  // https://github.com/PERTS/saturn/issues/81
  // The completion code is generated like this:
  //     Math.floor(Math.random() * Math.pow(10, 6)) + "4131"
  // and is essentially a human-verifiable signed token. Students will give
  // these to instructors who will check that the last four digits are as
  // expected, then give students credit for completing the survey.

  const codePrefix = Math.floor(Math.random() * Math.pow(10, 6));
  const code = `${codePrefix}${codeSuffix}`;
  const [showCode, setShowCode] = useState(false);

  return (
    <>
      <Question.Title>Thank you! You&rsquo;re all done.</Question.Title>
      <Question.Content>
        <CenteredText>
          <TextToSpeechContent>
            <p>
              <FakeLink onClick={() => setShowCode(!showCode)}>
                Click here if your instructor requested a{' '}
                <strong>completion code</strong>
              </FakeLink>
              .
            </p>
          </TextToSpeechContent>
          <Show when={showCode}>
            <TextToSpeechContent>
              <CompletionCode>{code}</CompletionCode>
            </TextToSpeechContent>
          </Show>
          <ImageStar />
          <TextToSpeechContent>
            <p>Thanks for giving your instructor feedback.</p>
          </TextToSpeechContent>
        </CenteredText>
      </Question.Content>
    </>
  );
};

export default QuestionCompletionCode;
