import { SurveyConfig } from 'types';
import { locales } from 'i18n';
import blockFromMetricsConfig from 'utils/blockFromMetricsConfig';

import { surveyLabel } from './config';
import SurveyIntroduction from './SurveyIntroduction';
import SurveyComplete from './SurveyComplete';
import blockDemographicsRaceGender from 'surveys/common/block-race-gender23-nocontext';

import MetricIntroMath from './MetricIntroMath';
import MetricIntroEnglish from './MetricIntroEnglish';
import MetricIntroScience from './MetricIntroScience';
import MetricIntroHistory from './MetricIntroHistory';

export const supportedLocales = [locales.ENGLISH, locales.SPANISH];

export const sesStudent23: SurveyConfig = {
  name: 'Student Pre/Post Survey',
  label: surveyLabel,
  survey: [
    {
      component: 'Page',
      label: 'introduction',
      progress: 1,
      questions: [
        {
          label: 'introduction',
          component: SurveyIntroduction,
          props: {},
        },
      ],
    },

    blockFromMetricsConfig(
      surveyLabel,
      'growth-mindset',
      'Growth Mindset',
      'growth-mindset',
      {},
    ).block,
    blockFromMetricsConfig(
      surveyLabel,
      'school-belonging',
      'School Belonging',
      'school-belonging',
      {},
    ).block,
    blockFromMetricsConfig(
      surveyLabel,
      'school-identity-safety',
      'School Identity Safety',
      'school-identity-safety',
      {},
    ).block,
    blockFromMetricsConfig(surveyLabel, 'effort', 'Effort', 'effort', {}).block,
    blockFromMetricsConfig(
      surveyLabel,
      'school-student-voice',
      'School Student Voice',
      'school-student-voice',
      {},
    ).block,

    // Math
    {
      component: 'Page',
      label: 'intro-math',
      questions: [
        {
          label: 'intro-math',
          component: MetricIntroMath,
          props: {},
        },
      ],
    },
    blockFromMetricsConfig(
      surveyLabel,
      'ses-student23-math',
      'Math',
      'ses-student23-math',
      {},
    ).block,

    // English
    {
      component: 'Page',
      label: 'intro-english',
      questions: [
        {
          label: 'intro-english',
          component: MetricIntroEnglish,
          props: {},
        },
      ],
    },
    blockFromMetricsConfig(
      surveyLabel,
      'ses-student23-english',
      'English',
      'ses-student23-english',
      {},
    ).block,

    // Science
    {
      component: 'Page',
      label: 'intro-science',
      questions: [
        {
          label: 'intro-science',
          component: MetricIntroScience,
          props: {},
        },
      ],
    },
    blockFromMetricsConfig(
      surveyLabel,
      'ses-student23-science',
      'Science',
      'ses-student23-science',
      {},
    ).block,

    // History
    {
      component: 'Page',
      label: 'intro-history',
      questions: [
        {
          label: 'intro-history',
          component: MetricIntroHistory,
          props: {},
        },
      ],
    },
    blockFromMetricsConfig(
      surveyLabel,
      'ses-student23-history',
      'History',
      'ses-student23-history',
      {},
    ).block,

    blockDemographicsRaceGender,

    {
      component: 'Page',
      label: 'thankyou',
      progress: 100,
      questions: [
        {
          label: 'surveycomplete',
          component: SurveyComplete,
          props: {},
        },
      ],
    },
  ],
};
