import { Question, TextToSpeechContent } from 'components';

const TeachingAtYourSchool = () => (
  <>
    <Question.Title>For the next few items&hellip;</Question.Title>
    <Question.Content>
      <TextToSpeechContent>
        <p>
          For the next few items, think about your experiences so far teaching
          at your specific school and rate how true each statement is.
        </p>
      </TextToSpeechContent>
    </Question.Content>
  </>
);

export default TeachingAtYourSchool;
