import React from 'react';
import styled from 'styled-components/macro';

const PageTitleStyled = styled.h1`
  font-size: 28px;
`;

const PageTitle: React.FC = ({ children }) => (
  <PageTitleStyled className="PageTitle">{children}</PageTitleStyled>
);

export default PageTitle;
