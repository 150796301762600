import React, { useEffect, useState } from 'react';
import { Button } from 'perts-ui';
import { FormattedMessage } from 'react-intl';
import { Show } from 'components';
import PageNavigationStyled from './PageNavigationStyled';
import TakeYourTime from './TakeYourTime';
import { FaChevronCircleRight } from 'react-icons/fa';

type Props = {
  // Optional, text that will be displayed when the user is told to wait.
  children?: any;
  // Is page navigation disabled?
  disabled?: boolean;
  // pageLabel from route
  pageLabel: string;
  // Click handler for Next button.
  onClickNext: () => void;
};

const PageNavigationTimed: React.FC<Props> = ({
  children,
  disabled = false,
  pageLabel,
  onClickNext,
}) => {
  // We won't display the "take your time" message unless the user clicks the
  // Next button before the timer has run down so that we don't bother users
  // that are naturally taking their time with unnecessary UI.
  const [showTakeYourTime, setShowTakeYourTime] = useState(false);

  useEffect(() => {
    // Hide message on route change. If the message is displaying and the user
    // clicks the back button, for example, we don't want to continue displaying
    // the "take your time" message.
    setShowTakeYourTime(false);
  }, [pageLabel]);

  const onClick = () => {
    setShowTakeYourTime(true);
    onClickNext();
  };

  return (
    <>
      <PageNavigationStyled className="PageNavigationTimed">
        <Button
          type="button"
          disabled={disabled || showTakeYourTime}
          icon={<FaChevronCircleRight />}
          onClick={onClick}
          data-test="ButtonNext"
        >
          <FormattedMessage id="ui.button.nextPage" />
        </Button>

        <Show when={showTakeYourTime}>
          <TakeYourTime>{children}</TakeYourTime>
        </Show>
      </PageNavigationStyled>
    </>
  );
};
export default PageNavigationTimed;
