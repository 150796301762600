import { SurveyQuestion } from 'types';

// Note: It seems safer to import these components directly from the component
// file then to import them from a index file. Importing from an index file
// results some of the components returning `undefined` here.

import Question from 'components/Question';

type RenderQuestionProps = {
  questions: SurveyQuestion[];
  visibleQuestion: SurveyQuestion;
  routeNext?: Function;
  setNextRoute?: Function;
};

export const RenderQuestion = ({
  questions,
  visibleQuestion,
  routeNext,
  setNextRoute,
}: RenderQuestionProps) => {
  return (
    <>
      {questions.map((question) => {
        const Component = question.component;

        return (
          <Question
            key={question.label}
            data-test={`question-${question.label}`}
          >
            <Component
              label={question.label}
              routeNext={routeNext}
              setNextRoute={setNextRoute}
              display={question === visibleQuestion}
              {...question.props}
            />
          </Question>
        );
      })}
    </>
  );
};
