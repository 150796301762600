type RaceOption = {
  /**
   * The name of the checkbox in the form, the value that will be stored in the
   * answers map as true. If name is "race_foo" and that checkbox is checked
   * and submitted then `"race_foo": true` will be in the answers.
   */
  name: string;

  /**
   * Key to look up the checkbox label in our i18n messages.
   */
  messageLabel: string;
};

type RaceCategory = {
  /**
   * Key to look up the checkbox label in our i18n messages.
   */
  messageLegend: string;

  /**
   * Should match the config for the `race-7-preference` attribute in
   * @perts/config.
   */
  raceAttributeValue: string | undefined;

  /**
   * If this should be included in QuestionRaceFollowup ("preference").
   */
  validRacePreference: boolean;

  /**
   * If this should be included in QuestionRace.
   */
  validRaceInitialChoice: boolean;

  /**
   * The checkboxes that should appear in this fieldset/category.
   */
  options: RaceOption[];
};

const raceOptionsByCategory: RaceCategory[] = [
  {
    messageLegend: 'native_american',
    raceAttributeValue: 'american-indian-alaskan-native',
    validRacePreference: true,
    validRaceInitialChoice: true,
    options: [
      {
        name: 'race_amindian',
        messageLabel: 'race_amindian',
      },
    ],
  },
  {
    messageLegend: 'asian_american',
    raceAttributeValue: 'asian-asian-american',
    validRacePreference: true,
    validRaceInitialChoice: true,
    options: [
      {
        name: 'race_e_asian',
        messageLabel: 'race_e_asian',
      },
      {
        name: 'race_s_asian',
        messageLabel: 'race_s_asian',
      },
      {
        name: 'race_se_asian',
        messageLabel: 'race_se_asian',
      },
      {
        name: 'race_o_asian',
        messageLabel: 'race_o_asian',
      },
    ],
  },
  {
    messageLegend: 'hispanic_latino',
    raceAttributeValue: 'latinx',
    validRacePreference: true,
    validRaceInitialChoice: true,
    options: [
      {
        name: 'race_cuban',
        messageLabel: 'race_cuban',
      },
      {
        name: 'race_central_american',
        messageLabel: 'race_central_american',
      },
      {
        name: 'race_dominican',
        messageLabel: 'race_dominican',
      },
      {
        name: 'race_mex',
        messageLabel: 'race_mex',
      },
      {
        name: 'race_puerto_rican',
        messageLabel: 'race_puerto_rican',
      },
      {
        name: 'race_south_american',
        messageLabel: 'race_south_american',
      },
      {
        name: 'race_o_latino',
        messageLabel: 'race_o_latino',
      },
    ],
  },
  {
    messageLegend: 'black',
    raceAttributeValue: 'black-african-american',
    validRacePreference: true,
    validRaceInitialChoice: true,
    options: [
      {
        name: 'race_african_american',
        messageLabel: 'race_african_american',
      },
      {
        name: 'race_african',
        messageLabel: 'race_african',
      },
      {
        name: 'race_caribbean',
        messageLabel: 'race_caribbean',
      },
      {
        name: 'race_o_black',
        messageLabel: 'race_o_black',
      },
    ],
  },
  {
    messageLegend: 'white',
    raceAttributeValue: 'white-european-american',
    validRacePreference: true,
    validRaceInitialChoice: true,
    options: [
      {
        name: 'race_european',
        messageLabel: 'race_european',
      },
      {
        name: 'race_o_white',
        messageLabel: 'race_o_white',
      },
    ],
  },
  {
    messageLegend: 'middle_eastern',
    raceAttributeValue: 'middle-eastern-north-african',
    validRacePreference: true,
    validRaceInitialChoice: true,
    options: [
      {
        name: 'race_middle_eastern',
        messageLabel: 'race_middle_eastern',
      },
      {
        name: 'race_n_african',
        messageLabel: 'race_n_african',
      },
    ],
  },
  {
    messageLegend: 'pacific_islander',
    raceAttributeValue: 'native-hawaiian-pacific-islander',
    validRacePreference: true,
    validRaceInitialChoice: true,
    options: [
      {
        name: 'race_pac_isl',
        messageLabel: 'race_pac_isl',
      },
    ],
  },
  // Appears only in the initial race question.
  {
    messageLegend: 'other',
    raceAttributeValue: undefined,
    validRacePreference: false,
    validRaceInitialChoice: true,
    options: [
      {
        name: 'race_other',
        messageLabel: 'race_other',
      },
    ],
  },
  // Appears only in the follow-up race question.
  {
    messageLegend: 'other',
    raceAttributeValue: undefined,
    validRacePreference: true,
    validRaceInitialChoice: false,
    options: [
      {
        name: 'race_other',
        messageLabel: 'race_other_followup',
      },
    ],
  },
  // Appears only in the initial race question.
  {
    messageLegend: 'opt_out',
    raceAttributeValue: undefined,
    validRacePreference: false,
    validRaceInitialChoice: true,
    options: [
      {
        name: 'race_opt_out',
        messageLabel: 'opt_out',
      },
    ],
  },
  // Appears only in the follow-up race question.
  {
    messageLegend: 'multi',
    raceAttributeValue: 'multi',
    validRacePreference: true,
    validRaceInitialChoice: false,
    options: [
      {
        name: 'race_multi',
        messageLabel: 'race_multi',
      },
    ],
  },
];

/**
 * Retrieves a unique array of race item values for Saturn demographics.
 *
 * @returns {string[]} An array of unique race item values.
 */

export const getRaceItemValues = () => {
  const raceItemValues = raceOptionsByCategory.flatMap((category) =>
    category.options.map((race) => race.name),
  );

  // Return the unique values.
  return [...new Set(raceItemValues)];
};

export const getRaceInitialChoiceCategories = () =>
  raceOptionsByCategory.filter((ctg) => ctg.validRaceInitialChoice);

export const getRacePreferenceOptions = () =>
  raceOptionsByCategory
    .filter((ctg) => ctg.validRacePreference)
    .flatMap((ctg) => ctg.options);

export const getRaceMultiOption = () => {
  const multiCategory = raceOptionsByCategory.find(
    (ctg) => ctg.messageLegend === 'multi',
  );
  const [multiOption] = multiCategory?.options || [];

  if (!multiOption) {
    throw new Error('Saturn misconfigured, cannot find race_multi option.');
  }
  return multiOption;
};
