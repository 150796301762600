// Catalyze21 Learning Conditions:
// https://docs.google.com/spreadsheets/d/1NojYfQWtrO5Ax4XiQVNVqxQ6r1L9P0Bm1li3JVlZUkk

import { ResponseDoc } from '../../index.d';
import sample from 'lodash/sample';

const pickOneRandomOpenResponse = (openResponseOptions: string[]) =>
  sample(openResponseOptions);

const addOpenResponse = (
  response: ResponseDoc,
  learningCondition: string,
  openResponseOptions: string[],
) =>
  response &&
  response.meta &&
  response.meta.open_response_lcs &&
  response.meta.open_response_lcs.includes(learningCondition)
    ? [pickOneRandomOpenResponse(openResponseOptions)]
    : [];

export default function (response: ResponseDoc) {
  return {
    ...response,
    meta: {
      ...response.meta,
      // Neptune portal will provide learning conditions to Saturn using the
      // `open_response_lcs` query param. At the time a new response is created
      // we will choose one question from each of these selected learning
      // conditions to display an open response question for.
      showOpenResponses: [
        // Collective Vision
        ...addOpenResponse(response, 'collective-vision', [
          'or_collective_vision_1',
          'or_collective_vision_2',
          'or_collective_vision_3',
          'or_collective_vision_4',
          'or_collective_vision_5',
        ]),
        // Inclusive Empowerment
        ...addOpenResponse(response, 'inclusive-empowerment', [
          'or_inclusive_empowerment_1',
          'or_inclusive_empowerment_2',
          'or_inclusive_empowerment_3',
          'or_inclusive_empowerment_4',
          'or_inclusive_empowerment_5',
        ]),
        // Learning Culture
        ...addOpenResponse(response, 'learning-culture', [
          'or_learning_culture_1',
          'or_learning_culture_2',
          'or_learning_culture_3',
          'or_learning_culture_4',
        ]),
        // Supportive Leadership
        ...addOpenResponse(response, 'supportive-leadership', [
          'or_supportive_leadership_1',
          'or_supportive_leadership_2',
          'or_supportive_leadership_3',
          'or_supportive_leadership_4',
          'or_supportive_leadership_5',
        ]),
        // Transformative Equity
        ...addOpenResponse(response, 'transformative-equity', [
          'or_transformative_equity_1',
          'or_transformative_equity_2',
          'or_transformative_equity_3',
          'or_transformative_equity_4',
          'or_transformative_equity_5',
        ]),
        // Trusting Community
        ...addOpenResponse(response, 'trusting-community', [
          'or_trusting_community_1',
          'or_trusting_community_2',
          'or_trusting_community_3',
          'or_trusting_community_4',
          'or_trusting_community_5',
        ]),
      ],
    },
  };
}
