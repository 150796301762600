/* eslint-disable max-len */

// Common characters (https://www.w3.org/wiki/Common_HTML_entities_used_for_typography)
// Prefer – to -      [En dash]
// Prefer — to --     [Em (long) dash]
// Prefer ‘ to '      [Left single quote]
// Prefer ’ to '      [Right single quote, Apostrophe]
// Prefer “ to "      [Left double quote]
// Prefer ” to "      [Right double quote]
// Prefer … to ...    [Ellipsis]

import locales from '../locales';

export default {
  [locales.SPANISH]: {
    'ui.button.nextPage': 'Siguiente Página',
    'ui.button.takeYourTime':
      'Por favor tome el tiempo de leer esto cuidadosamente.',
    'ui.redirecting': 'Dirigiendo a {url}',
    'ui.survey.loading': 'Recuperando tu encuesta.',
    'ui.survey.error.badResponseId':
      'Lo sentimos. No pudimos encontrar tu encuesta.',
    'ui.survey.error.badSurveyCode': 'Lo sentimos. Esa encuesta no existe.',
    'ui.survey.error.generic':
      'Lo sentimos. Hubo un problema preparando tu encuesta. Por favor recarga la página para intentarlo de nuevo.',
    'ui.survey.welcome': 'Bienvenido. Se está configurando tu encuesta.',
    'ui.textToSpeech.on': 'Texto a voz activado',
    'ui.textToSpeech.off': 'Texto a voz desactivado',
    'ui.openResponse.label':
      'Explica cómo elegiste tu respuesta. También puedes agregar cualquier otra cosa sobre tu respuesta que te gustaría compartir.',
    'q.fidelityHonest.answer.yes': 'Si',
    'q.fidelityHonest.answer.no': 'No',

    // Demographics: Gender
    'q.gender.label': '¿Cuál es tu identidad de género?',
    'q.gender.answer.female': 'Niña o Mujer',
    'q.gender.answer.male': 'Niño u Hombre',
    'q.gender.answer.other': 'No Binario u Otro',
    'q.gender.answer.opt_out': 'Prefiero no responder',

    // Demographics: Race
    'q.race.label':
      '¿Con cuál grupo o grupos te identificas? Por favor seleccione las casillas que correspondan.',
    'q.race.label.followup': 'De tus elecciones, ¿con cuál te identificas más?',

    'q.race.legend.native_american': 'Nativo Americano',
    'q.race.label.race_amindian': 'Indígena de las Américas o Nativo de Alaska',

    'q.race.legend.asian_american': 'Asiático / Asiático-Americano',
    'q.race.label.race_e_asian':
      'Asiático del Este (e.j., Chino, Japonés, Coreano, Taiwanés)',
    'q.race.label.race_se_asian':
      'Asiático del Sudeste (e.j., Filipino, Indonesio, Vietnamita)',
    'q.race.label.race_s_asian':
      'Asiático del Sur (e.j., Pakistani, Indio, Nepalés, Sri Lanka)',
    'q.race.label.race_o_asian': 'Otro Asiático',

    'q.race.legend.hispanic_latino': 'Hispano / Latinx',
    'q.race.label.race_mex': 'Mexicano Americano / Chicano',
    'q.race.label.race_puerto_rican': 'Puertorriqueño',
    'q.race.label.race_cuban': 'Cubano',
    'q.race.label.race_dominican': 'Dominicano',
    'q.race.label.race_central_american': 'Centro Americano',
    'q.race.label.race_south_american': 'Sur Americano',
    'q.race.label.race_o_latino': 'Otro Hispano o Latino',

    'q.race.legend.black': 'Negro',
    'q.race.label.race_african_american': 'Afroamericano / Negro',
    'q.race.label.race_african': 'Africano',
    'q.race.label.race_caribbean': 'Caribeño',
    'q.race.label.race_o_black': 'Otro Negro',

    'q.race.legend.white': 'Blanco',
    'q.race.label.race_european': 'Europeo / Europeo Americano',
    'q.race.label.race_o_white': 'Otro Blanco',

    'q.race.legend.middle_eastern': 'Medio-Oriental / Norteafricano',
    'q.race.label.race_middle_eastern':
      'Medio-Oriental / Medio-Oriental Americano ',
    'q.race.label.race_n_african':
      'Norteafricano (e.g., Argelino, Egipcio, Libio, Marroquí, Sudanés, Tunecino)',

    'q.race.legend.pacific_islander': 'Islas del Pacífico',
    'q.race.label.race_pac_isl': 'Nativo de Hawái u otra Isla del Pacífico',

    'q.race.legend.other': 'Otro',
    'q.race.label.race_other': 'Otro Grupo (por favor especifica)',
    'q.race.label.race_other_followup': 'Otro Grupo',
    'q.race.label.race_multi': 'Identifico igualmente con más de un grupo.',
    'q.race.label.race_text': 'Si otro, por favor especifica',
    'q.race.placeholder.race_text': 'Por favor especifica',

    'q.race.legend.opt_out': 'Prefiero no responder',
    'q.race.label.opt_out': 'Prefiero no responder',

    // Demographics Grade
    'q.grade.label': '¿En qué grado estás?',
    'q.grade.answer.grade3': '3º',
    'q.grade.answer.grade4': '4º',
    'q.grade.answer.grade5': '5º',
    'q.grade.answer.grade6': '6º',
    'q.grade.answer.grade7': '7º',
    'q.grade.answer.grade8': '8º',
    'q.grade.answer.grade9': '9º (primer año de preparatoria)',
    'q.grade.answer.grade10': '10º (segundo año de preparatoria)',
    'q.grade.answer.grade11': '11º (tercer año de preparatoria)',
    'q.grade.answer.grade12': '12º (último año de preparatoria)',
    'q.grade.answer.other': 'Mi grado no está aquí',
    'q.grade.answer.opt_out': 'Prefiero no responder',

    // Likert Scale
    'q.likert.answer.stronglyDisagree': 'Totalmente en desacuerdo',
    'q.likert.answer.disagree': 'En desacuerdo ',
    'q.likert.answer.slightlyDisagree': 'Un poco en desacuerdo',
    'q.likert.answer.neither': 'Ni de acuerdo ni en desacuerdo',
    'q.likert.answer.slightlyAgree': 'Un poco de acuerdo',
    'q.likert.answer.agree': 'De acuerdo',
    'q.likert.answer.stronglyAgree': 'Totalmente de acuerdo',

    // Contextual Messages
    'context.thisQuestionIsAbout': 'Esta pregunta es sobre {class_name}.',
    'context.demographics':
      'Por favor responde cuidadosamente. No podras cambiar tu respuesta para {class_name}.',
  },
};
