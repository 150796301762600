import { LocaleSwitch, Question, TextToSpeechContent } from 'components';
import { locales } from 'i18n';

const messages = {
  [locales.ENGLISH]: () => (
    <>
      <Question.Title>
        For the next three questions, think about your history class.
      </Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            For the next three questions, think about your <u>history</u> class.
            History includes subjects like Social Studies, World History, and
            also history classes devoted to studying specific places or groups
            of people, like American History, African-American History, European
            History (and many others).
          </p>
          <p>
            If you take more than one history class, please think about{' '}
            <u>all</u> of your history classes.
          </p>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),

  [locales.SPANISH]: () => (
    <>
      <Question.Title>
        Para las próximas tres preguntas, piensa en tu clase de historia.
      </Question.Title>
      <Question.Content>
        <TextToSpeechContent>
          <p>
            Para las siguientes tres preguntas, piensa en tu clase de{' '}
            <u>historia</u>. La historia incluye materias como estudios
            sociales, historia mundial y también clases de historia dedicadas al
            estudio de lugares o grupos específicos de personas, como la
            historia estadounidense, la historia afroamericana, la europea
            Historia (y muchas otras).
          </p>
          <p>
            Si toma más de una clase de historia, piense en <u>todas</u> de tus
            clases de historia.
          </p>
        </TextToSpeechContent>
      </Question.Content>
    </>
  ),
};

const HistoryIntroduction = () => (
  <LocaleSwitch label="sesStudent23HistoryIntro" messages={messages} />
);

export default HistoryIntroduction;
