// TODO: Write test that makes sure ALL string keys in
//   en messages file also appear in sp messages file.

import React, { useContext } from 'react';

// https://www.rfc-editor.org/rfc/bcp/bcp47.txt
// https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
const locales = {
  ENGLISH: 'en',
  SPANISH: 'es',
};

export const DEFAULT_LOCALE = locales.ENGLISH;

const localesConfig = {
  [locales.ENGLISH]: {
    tag: locales.ENGLISH,
    text: 'English',
    voices: [
      // Preferred voice: Samantha
      { lang: /^en-US/i, name: 'Samantha' },
      // Preferred voice: Fred
      { lang: /^en-US/i, name: 'Fred' },
      // Use the first English voice found.
      { lang: /^en/i },
    ],
  },
  [locales.SPANISH]: {
    tag: locales.SPANISH,
    text: 'Español',
    voices: [
      // Preferred voice: Paulina
      { lang: /^es-MX/i, name: 'Paulina' },
      // Preferred voice: Juan
      { lang: /^es-MX/i, name: 'Juan' },
      // Use the first Spanish voice found.
      { lang: /^es/i },
    ],
  },
};

export const getLocaleConfigByTag = (tag) => localesConfig[tag];

export const LocaleContext = React.createContext();

// Do this:
//   import { useLocaleContext } from 'i18n';
//   const { locale } = useLocaleContext();
// Instead of this:
//   import { useContext } from 'react';
//   import { LocaleContext } from 'i18n';
//   const { locale } = useContext(LocaleContext);
export const useLocaleContext = () => useContext(LocaleContext);

export default locales;
