import { UnorderedList, ListItem } from 'perts-ui';
import { Question, TextToSpeechContent } from 'components';
import { FaCheck } from 'react-icons/fa';

export default () => (
  <>
    <Question.Title>Your opinion matters!</Question.Title>
    <Question.Content>
      <TextToSpeechContent>
        <p>
          Your instructor wants to know how to make this class a better place
          for you to learn. This 5-minute survey is a chance for you to provide
          honest, private feedback.
        </p>

        <UnorderedList>
          <ListItem icon={<FaCheck />}>
            This survey is only about the class taught by the instructor who
            invited you to take the survey.
          </ListItem>
          <ListItem icon={<FaCheck />}>
            To protect your privacy, we will not share your name with your
            instructor in connection with your answers. Instructors get reports
            that group students&rsquo; answers together. You can{' '}
            <a href="https://perts.net/ascend/class-report">
              view this sample report
            </a>{' '}
            as an example.
          </ListItem>
          <ListItem icon={<FaCheck />}>
            Please be honest. There are no right or wrong answers.
          </ListItem>
          <ListItem icon={<FaCheck />}>
            If you don&rsquo;t understand a question, ask your instructor for
            help.
          </ListItem>
        </UnorderedList>
      </TextToSpeechContent>
    </Question.Content>
  </>
);
