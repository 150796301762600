type SurveysType = {
  [label: string]: {
    label: string;
  };
};

export const surveys: SurveysType = {
  ascend23: { label: 'ascend23' },
  ascend24: { label: 'ascend24' },
  catalyze23: { label: 'catalyze23' },
  catalyze24: { label: 'catalyze24' },
  elevate23: { label: 'elevate23' },
  elevate24: { label: 'elevate24' },
  sesTeacher23: { label: 'sesTeacher23' },
  sesStudent23: { label: 'sesStudent23' },
};

export const raceMultiValue = 'race_multi';

export const defaultMyClassName = 'my class';
export const defaultYourClassName = 'your class';
