import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useLocaleContext,
  locales,
  getLocaleConfigByTag,
  getSupportedLocales,
} from 'i18n';
import { updateResponse } from 'services';
import LocaleSelectorStyled from './LocaleSelectorStyled';

const LocaleSelector = () => {
  const [show, setShow] = useState(false);
  const { responseId, surveyLabel } = useParams();
  const { locale } = useLocaleContext();

  useEffect(() => {
    // Display the LocaleSelector if there is more than one supported locale.
    async function shouldShow() {
      const supportedLocales = await getSupportedLocales(surveyLabel);
      if (Array.isArray(supportedLocales) && supportedLocales.length > 1) {
        setShow(true);
      }
    }

    shouldShow();
  });

  if (!show) {
    return null;
  }

  const onChange = (e) => {
    updateResponse(responseId, { locale: e.target.value });
  };

  const localeOptions = Object.keys(locales).map((localeLangKey) =>
    getLocaleConfigByTag(locales[localeLangKey]),
  );

  return (
    <LocaleSelectorStyled data-test="LocaleSelector">
      <select name="locale" value={locale} onChange={onChange}>
        {localeOptions.map((option) => (
          <option key={option.tag} value={option.tag}>
            {option.text}
          </option>
        ))}
      </select>
    </LocaleSelectorStyled>
  );
};

export default LocaleSelector;
