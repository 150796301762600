import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { AnimatePresence } from 'framer-motion';
import { useLocaleContext } from 'i18n';
import RedirectToPortal from 'scenes/RedirectToPortal';
import Responses from 'scenes/Responses';
import SurveyGenerateUrl from 'scenes/SurveyGenerateUrl';
import SurveyNew from 'scenes/SurveyNew';
import Survey from 'scenes/Survey';
import { AppContainer } from 'components';
import isDevelopment from 'utils/isDevelopment';

const App: React.FC = () => {
  const { locale } = useLocaleContext();

  return (
    <AppContainer lang={locale}>
      <Router>
        {/* <AnimatePresence exitBeforeEnter> */}
        <Switch>
          {isDevelopment() && (
            <Route path="/responses">
              <Responses />
            </Route>
          )}

          <Route exact path="/surveys/:surveyLabel/testurl">
            <SurveyGenerateUrl />
          </Route>

          <Route exact path="/surveys/:surveyLabel">
            <SurveyNew />
          </Route>

          <Route exact path="/surveys/:surveyLabel/:responseId">
            <Survey />
          </Route>

          <Route exact path="/surveys/:surveyLabel/:responseId/:pageLabel">
            <Survey />
          </Route>

          <Route path="/">
            <RedirectToPortal />
          </Route>
        </Switch>
        {/* </AnimatePresence> */}
      </Router>
    </AppContainer>
  );
};

export default App;
